@import "../../../scss/global/colors";
.card--grey-icon {
  background-color: $darkWhitePrimary;
  border-radius: 1.25rem;
  padding: 1.38rem 1.31rem 1.87rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (max-width: 550px) {
  .card--grey-icon {
    flex-direction: row;
    align-items: center;
    border-radius: 0.625rem;
    padding: 1.13rem 1.31rem;
    justify-content: flex-start;
    position: relative;
    &:before {
      content: '';
      background-image: url('/img/icons/arrow-right--light-gray.svg');
      display: block;
      position: absolute;
      width: 1rem;
      z-index: 2;
      height: 1rem;
      right: 1rem;
      object-fit: cover;
      object-position: center;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}