.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 46px;
  .pagination-list {
    display: flex;
    align-items: center;
    gap: 14px;
    .pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      .pagination-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        &.active {
          color: #F7212E;
        }
        i {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .pagination {
    padding-top: 32px;
    .pagination-list {
      gap: 9px;
      .pagination-item {
        .pagination-item-link {
          font-size: 14px;
          &.active {
          }
          i {
          }
        }
      }
    }
  }
}