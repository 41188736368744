@import "../../../scss/global/colors";
.product-info {
  display: flex;
  border-radius: 1.25rem;
  background: $darkWhitePrimary;
  flex: 7;
  padding: 2.63rem 2.63rem 4rem;
  justify-content: space-between;
  flex-direction: column;
  .product-info-top {
    display: flex;
    flex-direction: column;
    gap: 1.38rem;
    max-width: 52rem;
    .product-info--title {
      font-size: 1.625rem;
      font-weight: 700;
    }
    .product-info--description {
      font-size: 1.125rem;
      font-weight: 400;
    }
  }
  .product-info-bottom {
    display: flex;
    gap: 2rem;
    align-items: center;
    .product-info--price {
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
}
@media only screen and (max-width: 980px) {
  .product-info {
    padding: 1.5rem 1.5rem 2rem;
    gap: 1.75rem;
    .product-info-top {
      .product-info--title {
        font-size: 1.25rem;
      }
      .product-info--description {
        font-size: 0.75rem;
      }
    }
    .product-info-bottom {
      flex-direction: column;
      align-items: flex-start;
      .product-info--price {
      }
      button {
        width: 100%;
      }
    }
  }
}