.about--wrapper {
  padding-top: 4.81rem;
  padding-bottom: 8.12rem;
}
@media only screen and (max-width: 1240px) {
  .about--wrapper {
    padding-top: unset;
  }
}

@media only screen and (max-width: 980px) {
  .about--wrapper {
    padding-bottom: 0;
  }
}