@import "./global/colors";

.big-menu {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 999;
  overflow: hidden;
}

.head-menu {
  background-color: #EFEFF5;
  padding-left: 4.38rem;
  padding-right: 13.56rem;
  padding-top: 8rem;
  align-items: stretch;
  //display: none;
  height: auto;
  position: absolute;
  width: 100%;
  //margin-top: .94rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  &.active {
    //display: block;
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
    position: relative;
  }

  .head-menu--row {
    display: flex;
    padding-bottom: 1.68rem;

    .head-menu-left {
      flex: 2;
    }

    .head-menu-right {
      flex: 8;
    }
  }

  .contact-us-block {
    padding-left: unset;
    padding-right: unset;
    padding-bottom: unset;

    .contact-us-block--wrapper {
      padding: 2rem 4.5rem 2rem 4.5rem;
    }
  }

  .contact-addresses-menu {
    .contact-address-menu-item {
      p.contact-address-menu-item-city {
        color: $blackPrimary;
      }

      .contact-address-menu-item-phone {
        color: $blackPrimary;
      }

      .contact-address-menu-item-address {
        color: $blackPrimary;
      }
    }
  }

  .contact-address-block {
    .phone-base {
      .phone-base--title {
        color: $blackPrimary;
      }

      .phone-base--subtitle {
        color: rgba(51, 54, 60, 0.22);;
      }

      .phone-base--link {
        color: $blackPrimary;
      }
    }
  }
  .contact-address-block {
    .email-base {
      .email-base--title {
        color: $blackPrimary;
      }
      .email-base--link {
        color: $blackPrimary;
      }
    }
  }
}

.menu-column {
  ul {
    display: flex;
    flex-direction: column;
    gap: 2.6rem;

    .menu-column--item {
      a {
        &.current_page_item, &:hover {
          color: $redPrimary
        }
      }
      &.current-menu-item ,&.current_page_item {
        a {
          color: $redPrimary
        }
      }
    }
  }
}

.menu-column--item-link {
  font-weight: 700;
  display: flex;
  align-items: center;

  i {
    margin-right: 0.75rem;
  }

  &.active, &:hover {
    color: $redPrimary
  }
}

.menu-cards {
  height: 100%;

  ul {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.8125rem;

    .menu-card-item {
      height: 100%;
      border-radius: 1.25rem;
      width: calc((100% - 1.8125rem) / 4);
      padding: 2.88rem 1.69rem 2.44rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      background-size: cover;

      .menu-card-item-title {
        color: $whitePrimary;
      }

      .menu-card-item-link {
        padding: 0.75rem 1rem;
      }
    }
  }
}

.head-menu-right {
  .menu-head--footer {
    display: flex;
    gap: 5rem;

    .menu-head--footer {
      .contact-addresses-menu {
      }
    }
  }
}

.head-catalog {
  padding-top: 4.63rem;
  padding-left: 4.38rem;
  padding-right: 4.38rem;
  //display: none;
  height: 107ch;
  background-color: #EFEFF5;
  position: absolute;
  width: 100%;
  margin-top: .94rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  &.active {
    //display: block;
    visibility: visible;
    position: relative;
    opacity: 1;
    transition-delay: 0s;
  }
}

.head-catalog--column {
  display: flex;
  gap: 11.25rem;
  align-items: stretch;
  .menu-column {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
    height: auto;
    .menu-column--body {
      display: flex;
      gap: 1.88rem;
      ul {
        flex: 3;
        gap: 1.63rem;
        li {
        }
      }
    }
  }
  .contact-us-block {
    padding-left: unset;
    padding-right: unset;
    padding-bottom: unset;
    height: 100%;
    max-width: 17rem;

    .contact-us-block--wrapper {
      padding: 2.87rem 1.69rem 2.44rem;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      align-items: flex-start;
      .contact-us-block--left {
        gap: 1rem;
        display: flex;
        flex-direction: column;
        .contact-us-block--subtitle {
          font-size: 1rem;
        }
      }
      .contact-us-block--right {
        button {
          padding: .75rem 1rem;
          border: 1px solid #FFF;
          background-color: unset;
          color: $whitePrimary;
        }
      }
    }
  }
}

@media only screen and (max-width: 1490px) {
  .big-menu {
  }

  .head-menu {
    padding-right: 4.38rem;
    &.active {
    }

    .head-menu--row {
      .head-menu-left {
      }

      .head-menu-right {
      }
    }

    .contact-us-block {
      .contact-us-block--wrapper {
      }
    }

    .contact-addresses-menu {
      .contact-address-menu-item {
        p.contact-address-menu-item-city {
        }

        .contact-address-menu-item-phone {
        }

        .contact-address-menu-item-address {
        }
      }
    }

    .contact-address-block {
      .phone-base {
        .phone-base--title {
        }

        .phone-base--subtitle {
        }

        .phone-base--link {
        }
      }
    }
    .contact-address-block {
      .email-base {
        .email-base--title {
        }
        .email-base--link {
        }
      }
    }
  }

  .menu-column {
    ul {
      .menu-column--item {
      }
    }
  }

  .menu-column--item-link {
    i {
    }

    &:hover {
    }
  }

  .menu-cards {
    ul {
      .menu-card-item {
        .menu-card-item-title {
        }

        .menu-card-item-link {
        }
      }
    }
  }

  .head-menu-right {
    .menu-head--footer {
      .menu-head--footer {
        .contact-addresses-menu {
        }
      }
    }
  }

  .head-catalog {
    &.active {
    }
  }

  .head-catalog--column {
    gap: 6rem;
    .menu-column {
      .menu-column--body {
        ul {
          li {
          }
        }
      }
    }
    .contact-us-block {
      .contact-us-block--wrapper {
        .contact-us-block--left {
          .contact-us-block--subtitle {
          }
        }
        .contact-us-block--right {
          button {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1390px) {
  .big-menu {
  }

  .head-menu {
    padding-left: 2.38rem;
    padding-right: 2.38rem;
    padding-top: 2rem;
    &.active {
    }

    .head-menu--row {
      .head-menu-left {
      }

      .head-menu-right {
      }
    }

    .contact-us-block {
      .contact-us-block--wrapper {
      }
    }

    .contact-addresses-menu {
      .contact-address-menu-item {
        p.contact-address-menu-item-city {
        }

        .contact-address-menu-item-phone {
        }

        .contact-address-menu-item-address {
        }
      }
    }

    .contact-address-block {
      .phone-base {
        .phone-base--title {
        }

        .phone-base--subtitle {
        }

        .phone-base--link {
        }
      }
    }
    .contact-address-block {
      .email-base {
        .email-base--title {
        }
        .email-base--link {
        }
      }
    }
  }

  .menu-column {
    ul {
      .menu-column--item {
        .menu-column--item-link {
          font-size: .875rem;
          i {
            width: .725rem;
            height: .725rem;
          }

          &:hover {
          }
        }
      }
    }
  }

  .menu-column--item-link {
    font-size: .875rem;
    i {
    }

    &:hover {
    }
  }

  .menu-cards {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      //grid-template-rows: 18rem;
      .menu-card-item {
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        gap: 3rem;
        .menu-card-item-title {
        }

        .menu-card-item-link {
        }
      }
    }
  }

  .head-menu-right {
    .menu-head--footer {
      .menu-head--footer {
        .contact-addresses-menu {
        }
      }
    }
  }

  .head-catalog {
    &.active {
    }
  }

  .head-catalog--column {
    gap: 6rem;
    .menu-column {
      .menu-column--body {
        ul {
          li {
          }
        }
      }
    }
    .contact-us-block {
      .contact-us-block--wrapper {
        .contact-us-block--left {
          .contact-us-block--subtitle {
          }
        }
        .contact-us-block--right {
          button {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .big-menu {
  }

  .head-menu {
    &.active {
    }

    .head-menu--row {
      .head-menu-left {
      }

      .head-menu-right {
      }
    }

    .contact-us-block {
      .contact-us-block--wrapper {
      }
    }

    .contact-addresses-menu {
      .contact-address-menu-item {
        p.contact-address-menu-item-city {
        }

        .contact-address-menu-item-phone {
        }

        .contact-address-menu-item-address {
        }
      }
    }

    .contact-address-block {
      .phone-base {
        .phone-base--title {
        }

        .phone-base--subtitle {
        }

        .phone-base--link {
        }
      }
    }
    .contact-address-block {
      .email-base {
        .email-base--title {
        }
        .email-base--link {
        }
      }
    }
  }

  .menu-column {
    ul {
      .menu-column--item {
      }
    }
  }

  .menu-column--item-link {
    i {
    }

    &:hover {
    }
  }

  .menu-cards {
    ul {
      .menu-card-item {
        .menu-card-item-title {
        }

        .menu-card-item-link {
        }
      }
    }
  }

  .head-menu-right {
    .menu-head--footer {
      .menu-head--footer {
        .contact-addresses-menu {
        }
      }
    }
  }

  .head-catalog {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    height: auto;
    &.active {
    }
  }

  .head-catalog--column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    .menu-column {
      &:nth-child(1){
        width: 50%;
      }
      &:nth-child(2){
        width: 50%;
      }
      &:nth-child(3){
        width: 100%;
      }
      .menu-column--body {
        ul {
          li {
          }
        }
      }
    }
    .contact-us-block {
      width: 100%;
      max-width: unset;
      .contact-us-block--wrapper {
        .contact-us-block--left {
          .contact-us-block--subtitle {
          }
        }
        .contact-us-block--right {
          button {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .big-menu {
  }

  .head-menu {
    &.active {
    }

    .head-menu--row {
      .head-menu-left {
      }

      .head-menu-right {
      }
    }

    .contact-us-block {
      .contact-us-block--wrapper {
      }
    }

    .contact-addresses-menu {
      .contact-address-menu-item {
        p.contact-address-menu-item-city {
        }

        .contact-address-menu-item-phone {
        }

        .contact-address-menu-item-address {
        }
      }
    }

    .contact-address-block {
      .phone-base {
        .phone-base--title {
        }

        .phone-base--subtitle {
        }

        .phone-base--link {
        }
      }
    }
    .contact-address-block {
      .email-base {
        .email-base--title {
        }
        .email-base--link {
        }
      }
    }
  }

  .menu-column {
    ul {
      .menu-column--item {
      }
    }
  }

  .menu-column--item-link {
    i {
    }

    &:hover {
    }
  }

  .menu-cards {
    ul {
      .menu-card-item {
        .menu-card-item-title {
        }

        .menu-card-item-link {
        }
      }
    }
  }

  .head-menu-right {
    .menu-head--footer {
      .menu-head--footer {
        .contact-addresses-menu {
        }
      }
    }
  }

  .head-catalog {
    padding-top: 2rem;
    &.active {
    }
  }

  .head-catalog--column {
    gap: 1.38rem;
    .menu-column {
      gap: 1rem;
      &:nth-child(1){
        width: 100%;
      }
      &:nth-child(2){
        width: 100%;
      }
      &:nth-child(3){
        width: 100%;
      }
      .menu-column--body {
        ul {
          gap: 1rem;
          li {
          }
        }
      }
      .menu-column--item-link {
        font-size: .875rem;
        i {
          width: .75rem;
          height: .75rem;
        }
      }
    }
    .contact-us-block {
      .contact-us-block--wrapper {
        .contact-us-block--left {
          .contact-us-block--subtitle {
          }
        }
        .contact-us-block--right {
          button {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .big-menu {
  }

  .head-menu {
    padding-top: 2.56rem;
    background-color: #f7f7fa;
    &.active {
    }

    .head-menu--row {
      flex-direction: column;
      .head-menu-left {
      }

      .head-menu-right {
      }
    }

    .contact-us-block {
      .contact-us-block--wrapper {
      }
    }

    .contact-addresses-menu {
      .contact-address-menu-item {
        p.contact-address-menu-item-city {
        }

        .contact-address-menu-item-phone {
        }

        .contact-address-menu-item-address {
        }
      }
    }

    .contact-address-block {
      .phone-base {
        .phone-base--title {
        }

        .phone-base--subtitle {
        }

        .phone-base--link {
        }
      }
    }
    .contact-address-block {
      .email-base {
        .email-base--title {
        }
        .email-base--link {
        }
      }
    }
  }

  .menu-column {
    padding-bottom: 2.56rem;
    ul {
      gap: 1rem;
      flex-direction: row;
      flex-wrap: wrap;
      .menu-column--item {
        white-space: nowrap;
      }
    }
  }

  .menu-column--item-link {
    i {
    }

    &:hover {
    }
  }

  .menu-cards {
    ul {
      .menu-card-item {
        .menu-card-item-title {
        }

        .menu-card-item-link {
        }
      }
    }
  }

  .head-menu-right {
    .menu-head--footer {
      .menu-head--footer {
        .contact-addresses-menu {
        }
      }
    }
  }

  .head-catalog {
    padding-top: 2rem;
    &.active {
    }
  }

  .head-catalog--column {
    gap: 1.38rem;
    grid-template-columns: 1fr;
    .menu-column {
      gap: 1rem;
      &:nth-child(1){
        width: 100%;
      }
      &:nth-child(2){
        width: 100%;
      }
      &:nth-child(3){
        width: 100%;
      }
      .menu-column--body {
        ul {
          gap: 1rem;
          li {
          }
        }
      }
      .menu-column--item-link {
        font-size: .875rem;
        i {
          width: .75rem;
          height: .75rem;
        }
      }
    }
    .contact-us-block {
      .contact-us-block--wrapper {
        .contact-us-block--left {
          .contact-us-block--subtitle {
          }
        }
        .contact-us-block--right {
          button {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .head-menu {
    //padding-left: unset;
    //padding-right: unset;
    &.active {
    }

    .head-menu--row {
      .head-menu-left {
      }

      .head-menu-right {
      }
    }

    .contact-us-block {
      .contact-us-block--wrapper {
      }
    }

    .contact-addresses-menu {
      .contact-address-menu-item {
        p.contact-address-menu-item-city {
        }

        .contact-address-menu-item-phone {
        }

        .contact-address-menu-item-address {
        }
      }
    }

    .contact-address-block {
      .phone-base {
        .phone-base--title {
        }

        .phone-base--subtitle {
        }

        .phone-base--link {
        }
      }
    }
    .contact-address-block {
      .email-base {
        .email-base--title {
        }
        .email-base--link {
        }
      }
    }
  }
  .menu-cards {
    ul {
      grid-template-columns: 1fr;
      gap: 1.31rem;
      .menu-card-item {
        .menu-card-item-title {
        }

        .menu-card-item-link {
        }
      }
    }
  }
  .head-menu-right {
    .menu-head--footer {
      flex-direction: column;
      gap: 1.31rem;
      .menu-head--footer {
        .contact-addresses-menu {
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .big-menu {
  }

  .head-menu {
    &.active {
    }

    .head-menu--row {
      .head-menu-left {
      }

      .head-menu-right {
      }
    }

    .contact-us-block {
      .contact-us-block--wrapper {
        padding: 2rem;
      }
    }

    .contact-addresses-menu {
      .contact-address-menu-item {
        p.contact-address-menu-item-city {
        }

        .contact-address-menu-item-phone {
        }

        .contact-address-menu-item-address {
        }
      }
    }

    .contact-address-block {
      .phone-base {
        .phone-base--title {
        }

        .phone-base--subtitle {
        }

        .phone-base--link {
        }
      }
    }
    .contact-address-block {
      .email-base {
        .email-base--title {
        }
        .email-base--link {
        }
      }
    }
  }

  .menu-column {
    ul {
      .menu-column--item {
      }
    }
  }

  .menu-column--item-link {
    i {
    }

    &:hover {
    }
  }

  .menu-cards {
    ul {
      .menu-card-item {
        padding: 1.88rem 1rem 1rem;
        .menu-card-item-title {
        }

        .menu-card-item-link {
        }
      }
    }
  }

  .head-menu-right {
    .menu-head--footer {
      .menu-head--footer {
        .contact-addresses-menu {
        }
      }
    }
  }

  .head-catalog {
    padding-top: 2rem;
    padding-left: unset;
    padding-right: unset;
    &.active {
    }
  }

  .head-catalog--column {
    gap: 1.38rem;
    .menu-column {
      gap: 1rem;
      &:nth-child(1){
        width: 100%;
      }
      &:nth-child(2){
        width: 100%;
      }
      &:nth-child(3){
        width: 100%;
      }
      .menu-column--body {
        flex-direction: column;
        ul {
          gap: 1rem;
          li {
          }
        }
      }
      .menu-column--item-link {
        font-size: .875rem;
        i {
          width: .75rem;
          height: .75rem;
        }
      }
    }
    .contact-us-block {
      .contact-us-block--wrapper {
        padding: 2rem;
        .contact-us-block--left {
          .contact-us-block--subtitle {
          }
        }
        .contact-us-block--right {
          button {
          }
        }
      }
    }
  }
}