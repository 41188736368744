.template-detail-text-image {
  .template-detail-text-image--title {
    color: #33363C;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 160%; /* 2rem */
    padding-bottom: 1.75rem;
  }

  .template-detail-text-image--body {
    display: flex;
    gap: 1.87rem;

    .template-detail-text-image--text {
      flex: 5;

      * {
        color: #33363C;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 160%; /* 1.8rem */
      }
    }

    .template-detail-text-image--img {
      flex: 5;
      border-radius: 0.9375rem;
      overflow: hidden;
      position: relative;
      object-fit: cover;
      object-position: left;
      max-height: 377px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      aspect-ratio: 2 / 3;

      /*&:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.78) 0%, rgba(0, 0, 0, 0.00) 100%);
        content: '';
      }*/
    }
  }
}

@media only screen and (max-width: 1200px) {
  .template-detail-text-image {
    .template-detail-text-image--body {
      .template-detail-text-image--img {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .template-detail-text-image {
    .template-detail-text-image--title {
      font-size: 1rem;
      padding-bottom: .75rem;
    }

    .template-detail-text-image--body {
      .template-detail-text-image--text {
        * {
          font-size: 0.875rem;
        }
      }

      .template-detail-text-image--img {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .template-detail-text-image {
    .template-detail-text-image--body {
      display: grid;
      grid-template-rows: 1fr 1fr;

      .template-detail-text-image--img {
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .template-detail-text-image {
    .template-detail-text-image--body {
      display: grid;
      grid-template-rows: 1fr 1fr;

      .template-detail-text-image--img {
        //height: 180px;
        height: auto;
        max-height: 183px;
        width: 100%;
      }
    }
  }
}