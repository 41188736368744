@import "./global/colors";
.about--wrapper {
  .ready-solutions-title {
    padding-top: 7.5rem;
    padding-bottom: 3.31rem;
    font-size: 2.875rem;
    color: $blackPrimary;
    max-width: 45.25rem;
  }
}
.about-block {
  display: flex;
  align-items: flex-start;
  padding-top: 7.5rem;
  gap: 11.69rem;
  padding-bottom: 4.25rem;
  .about-block--left {
    max-width: 54.375rem;
    .about-block--left--title {
      font-size: 2.875rem;
      color: $blackPrimary;
      span {
        color: $redPrimary;
      }
    }
    .advantages-card-big {
      background: unset;
      padding-bottom: unset;
      justify-content: flex-start;
      &:hover {
        border-color: transparent;
      }
      .advantages-card--item {
        width: 33%;
      }
    }
  }
  .about-block--right {
    max-width: 35.875rem;
    .about-block--right--title{
      font-size: 2.875rem;
      color: $blackPrimary;
      padding-bottom: 1.5rem;
    }
    .about-block--right--subtitle{
      padding-bottom: 3.31rem;
    }
    p {
    }
    .about-block--right--advantage {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2.31rem;
      .about-block--right--advantage-item {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
       .about-block--right--advantage-item--title {
         color: $redPrimary;
       }
        .about-block--right--advantage-item--body {

        }
      }
    }
    .about-block--right--advantage-row {
      .advantages-card-big {
        background: unset;
        justify-content: space-between;
        align-items: flex-end;
        &:hover {
          border-color: transparent;
        }
      }
    }
  }
}

.advantage-cards-mosaic {
  position: relative;
  .advantage-cards-mosaic--wrapper {
    //display: flex;
    gap: 1.87rem;
    flex-wrap: nowrap;
    max-height: 51rem;
    overflow-x: scroll;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .advantage-card-mosaic-item--down {
      position: relative;
      top: 2rem;
    }
  }
  &:before {
    content: '';
    display: block;
    //background-image: linear-gradient(rgba(255,255,255,0) 0,#fff 100%);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8590029761904762) 47%, rgba(255,255,255,0) 87%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 20%;
    z-index: 1;
  }
  &:after {
    content: '';
    display: block;
    //background-image: linear-gradient(rgba(255,255,255,0) 0,#fff 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8590029761904762) 47%, rgba(255,255,255,0) 87%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20%;
    z-index: 1;
  }
}

.advantage-card-mosaic-item {
  border-radius: 1.25rem;
  background: $darkWhitePrimary;
  padding: 1.25rem 1.25rem 1.25rem 1.63rem;
  height: 20.625rem;
  position: relative;
  min-width: 184px;
  //width: calc((100% - 1.623333333rem) / 3);
  .icon--circle-red {
  }
  .advantage-card-mosaic-item--title {
    font-size: 1.125rem;
    font-weight: 700;
    padding-bottom: 1rem;
    padding-top: 1.75rem;
  }
  .advantage-card-mosaic-item--body {

  }
}
@media only screen and (max-width: 1770px) {
  .about-block{
    gap: 7.69rem;
  }
}
@media only screen and (max-width: 1690px) {
  .about-block {
    gap: 5.69rem;
    .about-block--left {
      .about-block--left--title {
        font-size: 2.875rem;
      }
    }
    .about-block--right {
      .about-block--right--advantage-row {
        .advantages-card-big {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
@media only screen and (max-width: 1240px) {
  .about-block{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1.38rem;
    padding-bottom: 1.56rem;
    gap: 1.38rem;
    .about-block--left {
      max-width: unset;
      .about-block--left--title {
        font-size: 1.375rem;
        max-width: 60%;
      }
    }
    .about-block--right {
      max-width: unset;
      .about-block--right--advantage-row {
        .advantages-card-big {
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 8rem;
        }
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .about--wrapper {
    .ready-solutions-title {
      padding-top: 1.94rem;
      padding-bottom: 1.44rem;
      font-size: 1.375rem;
    }
  }
  .about-block{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1.38rem;
    padding-bottom: 1.56rem;
    gap: 1.38rem;
    .about-block--left {
      max-width: unset;
      .about-block--left--title {
        font-size: 1.375rem;
        max-width: 60%;
      }
    }
    .about-block--right {
      max-width: unset;
      .about-block--right--advantage-row {
        .advantages-card-big {
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 8rem;
        }
      }
      .about-block--right--title {
        font-size: 1.375rem;
      }
    }
    .about-block--left {
      .about-block--left--title {
        font-size: 1.375rem;
        max-width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .about-block{
    .about-block--left {
      width: 100%;
      overflow: scroll;
      .about-block--left--title {
      }
    }
    .about-block--right {
      .about-block--right--advantage{
        grid-template-columns: 1fr;
      }
      .about-block--right--advantage-row {
        .advantages-card-big {
          flex-direction: column;
          gap: 1.125rem;
          padding-left: unset;
        }
      }
      .about-block--right--title {
      }
    }
    .about-block--left {
      .advantages-card-big{
        padding-left: unset;
        gap: 1.875rem;
        .advantages-card--item {
          width: 100%;
        }
      }
      .about-block--left--title {
        max-width: 100%;
      }
    }
  }
  .advantage-cards-mosaic {
    &:before {
      background: linear-gradient(90deg,#fff 0,rgba(255,255,255,.8590029762) 47%,rgba(255,255,255,0) 87%);
      left: 0;
      top: 0;
      width: 15%;
      height: 100%;
      z-index: 1;
    }
    &:after {
      background: linear-gradient(270deg,#fff 0,rgba(255,255,255,.8590029762) 47%,rgba(255,255,255,0) 87%);
      right: 0;
      top: 0;
      width: 15%;
      height: 100%;
      z-index: 1;
      margin-right: 0;
      margin-left: auto;
    }
    .advantage-cards-mosaic--wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      .advantage-card-mosaic-item--down {
      }
    }
    &:before {
    }
    &:after {
    }
  }

  .advantage-card-mosaic-item {
    .icon--circle-red {
    }
    .advantage-card-mosaic-item--title {
    }
    .advantage-card-mosaic-item--body {
    }
  }
}