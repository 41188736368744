@import "../../../scss/global/colors";
.product-gallery-content {
  border-radius: 1.25rem;
  background: $darkWhitePrimary;
  padding: 6.25rem 11.38rem 1.88rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
  gap: 4rem;
  .splide {
    width: fit-content;
    height: fit-content;
    .splide__track {
      .splide__list {
        width: 100%;
        .splide__slide {
          width: 100%;
          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
    /*.splide__arrow {
      background: #0000;
      border: 0;
      color: #e5eeee;
      cursor: pointer;
      position: absolute;
      top: 50%;
    }*/
  }
  .product-gallery-main-slider {
    .splide__track {
      .splide__list {
        .splide__slide {
          img {
          }
        }
      }
    }
  }
  .product-gallery-thumbnail-slider {
    position: relative;
    .gallery-thumbnail-slider-arrows {
      .gallery-thumbnail-slider-arrow-prev {
        position: absolute;
        left: -3.25rem;
        z-index: 1;
        top: 15%;
        i {

        }
      }
      .gallery-thumbnail-slider-arrow-next {
        position: absolute;
        z-index: 1;
        right: -3rem;
        top: 15%;
        i {

        }
      }
    }
    .splide__track {
      .splide__list {
        .splide__slide {
          .splide__slider-wrapper {
            box-sizing: content-box;
            border-radius: 0.3125rem;
            border: 1px solid $lightGrayPrimary;
            padding: 1rem .19rem;
            position: relative;
            /*&:before {
              position: absolute;
              content: '';
              width: fit-content;
              height: auto;
              display: block;
              top: 0;
              left: 0;
              z-index: 2;
              background-color: rgba(154, 158, 167, 0.5);
              //margin: 1rem .19rem;
            }*/
            img {
              object-fit: cover;
              width: 100%;
              height: auto;
              position: relative;
              display: block;
            }
          }
          &.is-active{
            .splide__slider-wrapper {
              border: 1px solid $redPrimary;
              img {
              }
            }
            img {
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  .product-gallery-content {
    padding: 2.87rem 3.06rem 1.94rem;
    .splide {
      .splide__track {
        .splide__list {
          .splide__slide {
            img {
            }
          }
        }
      }
    }
    .product-gallery-main-slider {
      .splide__track {
        .splide__list {
          .splide__slide {
            img {
            }
          }
        }
      }
    }
    .product-gallery-thumbnail-slider {
      .splide__track {
        .splide__list {
          .splide__slide {
            img {
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .product-gallery-content {
    gap: 2.5rem;
    .splide {
      .splide__track {
        .splide__list {
          .splide__slide {
            img {
            }
          }
        }
      }
    }
    .product-gallery-main-slider {
      .splide__track {
        .splide__list {
          .splide__slide {
            img {
            }
          }
        }
      }
    }
    .product-gallery-thumbnail-slider {
      .gallery-thumbnail-slider-arrows {
        .gallery-thumbnail-slider-arrow-prev {
          position: absolute;
          left: -2.25rem;
          z-index: 1;
          top: 15%;
          i {
            width: 1.25rem;
            height: 1.25rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        .gallery-thumbnail-slider-arrow-next {
          max-width: 1.25rem;
          position: absolute;
          z-index: 1;
          right: -1rem;
          top: 15%;
          i {
            width: 1.25rem;
            height: 1.25rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
      .splide__track {
        .splide__list {
          .splide__slide {
            .splide__slider-wrapper {
              padding: .53rem 0.13rem;
              img {

              }
            }
          }
        }
      }
    }
  }
}