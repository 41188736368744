@import "../../../scss/global/colors";

.about-head {
  display: flex;
  align-items: flex-end;
  gap: 11.69rem;
  padding-bottom: 4.25rem;

  .about-head--title {
    max-width: 54.375rem;
    color: $redPrimary;
    font-size: 2.875rem;
  }

  .about-head--description {
    max-width: 35.875rem;
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.4rem */
  }
}

@media only screen and (max-width: 1770px) {
  .about-head {
    gap: 7.69rem;

    .about-head--title {
    }

    .about-head--description {
    }
  }
}

@media only screen and (max-width: 1690px) {
  .about-head {
    gap: 5.69rem;

    .about-head--title {

    }

    .about-head--description {
    }
  }
}

@media only screen and (max-width: 1240px) {
  .about-head {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .about-head--title {
      max-width: unset;
    }
  }
}
@media only screen and (max-width: 980px) {
  .about-head {
    gap: 1rem;
    padding-bottom: 1.38rem;
  }
}
@media only screen and (max-width: 850px) {
  .about-head {
    .about-head--title {
      font-size: 1.375rem;
    }
  }
}