@import "../../../scss/global/colors";
.breadcrumb {
  padding-top: 1.65rem;
  overflow: scroll;
  ul {
    flex-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    gap: 0.12rem;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: scroll;
    li{
      display: inline-flex;
      align-items: center;
      font-size: 0.875rem;
      color: rgba(51, 54, 60, 0.50);
      font-weight: 400;
      white-space: nowrap;
      &:not(:last-child) {
      }
      &:not(:last-child)::after {
        display: inline-flex;
        align-items: center;
        margin-left: 0.12rem;
        width: 0.6875rem;
        height: 0.6875rem;
        content: url("/img/icons/arrow-right--light-gray.svg");
      }
      &:not(:last-child) {
        a {

        }
      }
    }
  }
}
.breadcrumb--active {
  color: $blackPrimary;
}