@import "../../../scss/global/colors";

.product-overview {
  padding-top: 1.87rem;
  padding-bottom: 8.12rem;

  .product-overview--content--wrapper {
    .product-overview--bar {
      border-radius: 0.9375rem;
      display: flex;
      gap: 1.94rem;
      background-color: $darkWhitePrimary;
      padding: 1.37rem 2.75rem;
      overflow-x: scroll;
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      .product-overview--bar-item {
        font-size: 1.125rem;
        padding-right: 1.94rem;
        border-right: 1px rgba(154, 158, 167, 0.50) solid;
        white-space: nowrap;

        &:hover, &.active {
          color: $redPrimary;
          font-weight: 500;
        }
      }
    }

    .product-overview--bar-item--content {
      padding-top: 3.25rem;

      .product-overview-description--title {
        font-size: 1.25rem;
        font-weight: 700;
        padding-bottom: 1.37rem;
      }

      .product-overview-description--body {
        max-width: 64.125rem;
        color: $blackPrimary;
        font-family: Montserrat, serif;
        font-size: 1rem;
        line-height: 140%; /* 1.4rem */
        * {
          color: $blackPrimary;
          font-family: Montserrat, serif;
          font-size: 1rem;
          line-height: 140%; /* 1.4rem */
          padding-bottom: 1rem;
        }
      }
      .product-certs-body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 48px;
        column-gap: 46px;
        padding-top: 1.5rem;

        .product-certs-item {
          display: flex;
          align-content: center;
          justify-content: flex-start;
          gap: 12px;
          text-align: left;

          .product-certs-item-i {
            max-width: 2.625rem;
            max-height: 2.625rem;
            width: 100%;
            height: 100%;
            display: block;

            &.cert {
              width: 2.625rem;
              height: 2.625rem;
              display: block;

              &:before {
                content: url("data:image/svg+xml,%3Csvg height='100%25' width='100%25' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M36.5 15.5V24.25C36.5 33 33 36.5 24.25 36.5H13.75C5 36.5 1.5 33 1.5 24.25V13.75C1.5 5 5 1.5 13.75 1.5H22.5' stroke='%23F7212E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M36.5 15.5H29.5C24.25 15.5 22.5 13.75 22.5 8.5V1.5L36.5 15.5Z' stroke='%23F7212E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5596 19H17.1971C17.6696 19 18.2646 19.315 18.5096 19.7L20.4871 22.675C20.8896 23.27 20.8021 24.1625 20.2946 24.67L15.9896 28.975C15.3771 29.5875 14.3621 29.5875 13.7496 28.975L9.44457 24.67C8.93707 24.1625 8.84957 23.27 9.25207 22.675L11.2296 19.7C11.5096 19.315 12.1046 19 12.5596 19Z' stroke='%23F7212E' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                display: block;
                width: 2.625rem;
                height: 2.625rem;
              }
            }

            &.doc {
              display: block;
              width: 2.625rem;
              height: 2.625rem;

              &:before {
                content: url("data:image/svg+xml,%3Csvg height='100%25' width='100%25' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.5 17.5V26.25C38.5 35 35 38.5 26.25 38.5H15.75C7 38.5 3.5 35 3.5 26.25V15.75C3.5 7 7 3.5 15.75 3.5H24.5' stroke='%23F7212E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M38.5 17.5H31.5C26.25 17.5 24.5 15.75 24.5 10.5V3.5L38.5 17.5Z' stroke='%23F7212E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.25 22.75H22.75' stroke='%23F7212E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.25 29.75H19.25' stroke='%23F7212E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                display: block;
                width: 2.625rem;
                height: 2.625rem;
              }
            }
          }

          .product-characteristic-item-t {
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1240px) {
  .product-overview {
    .product-overview--content--wrapper {
      .product-overview--bar-item--content {
        .product-certs-body {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .product-overview {
    padding-bottom: 3rem;

    .product-overview--content--wrapper {
      .product-overview--bar {
        padding: 1.06rem 1.5rem;
        gap: .56rem;
        overflow-y: auto;

        .product-overview--bar-item {
          padding-right: .56rem;
          padding-left: unset;
          font-size: 0.75rem;
        }
      }

      .product-overview--bar-item--content {
        padding-top: 1.38rem;

        .product-overview-description--title {
          font-size: 0.875rem;
          padding-bottom: .5rem;
        }

        .product-overview-description--body {
          font-size: 0.75rem;

          * {
            font-size: 12px;
          }
        }

        .product-certs-body {
          max-width: 60%;
          grid-template-columns: repeat(2, 1fr);

          .product-certs-item {
            .product-certs-item-i {
              width: 1.625rem;
              height: 1.625rem;
              display: block;

              &.cert {
                width: 1.625rem;
                height: 1.625rem;

                &:before {
                  width: 1.625rem;
                  height: 1.625rem;
                  display: block;
                }
              }

              &.doc {
                width: 1.625rem;
                height: 1.625rem;

                &:before {
                  width: 1.625rem;
                  height: 1.625rem;
                  display: block;
                }
              }
            }

            .product-characteristic-item-t {
              display: inline-flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 690px) {
  .product-overview {
    .product-overview--content--wrapper {
      .product-overview--bar-item--content {
        .product-certs-body {
          max-width: 100%;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media only screen and (max-width: 490px) {
  .product-overview {
    .product-overview--content--wrapper {
      .product-overview--bar {
        border-radius: 10px;
      }

      .product-overview--bar-item--content {
        .product-certs-body {
          max-width: 55%;
          grid-template-columns: repeat(1, 1fr);
          row-gap: 22px;
        }
      }
    }
  }
}