.privacy-policy-content {
  max-width: 73.6875rem;
  padding-top: 3rem;

  * {
    color: #33363C;
    font-family: Montserrat, serif;
    font-weight: 500;
    line-height: 160%; /* 1.8rem */
    font-size: 1.125rem;
  }

  b {
    font-weight: 700;
  }

  p {
    font-weight: 500;
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  ol, ul {
    padding-bottom: 2rem;
    padding-left: 1.5rem;
  }
}

@media only screen and (max-width: 980px) {
  .privacy-policy-content {
    padding-top: 41px;
  }
}