@import "./global/colors";
.advantages {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1.87rem;
  grid-row-gap: 1.87rem;
  padding-top: 4.31rem;
  padding-bottom: 7.5rem;

  .advantages-card:nth-child(1) { grid-area: 1 / 1 / 3 / 2; }
  .advantages-card:nth-child(2) { grid-area: 1 / 2 / 2 / 7; }
  .advantages-card:nth-child(3) { grid-area: 2 / 2 / 3 / 3; }
  .advantages-card:nth-child(4) { grid-area: 2 / 3 / 3 / 4; }
  .advantages-card:nth-child(5) { grid-area: 2 / 4 / 3 / 5; }
  .advantages-card:nth-child(6) { grid-area: 2 / 5 / 3 / 6; }
  .advantages-card:nth-child(7) { grid-area: 2 / 6 / 3 / 7; }
}

.advantages-card-medium {
  background-color: $redPrimary;
  border-radius: 1.25rem;
  padding: 2.87rem 1.69rem 2.19rem 1.69rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .advantages-card--title {
    color: $whitePrimary;
    font-size: 1.625rem;
    font-weight: 700;
  }
  .advantages-card--button {
    .icon--arrow-right-white {
      margin-left: 1rem;
    }
    &:hover {
      .icon--arrow-right-white {
        background-image: url("/img/icons/arrow-red-right.svg");
        //margin-left: 1.3rem;
      }
    }
  }
}

.advantages-card-big {
  display:flex;
  padding-top: 4.06rem;
  padding-bottom: 4.06rem;
  background-color: $darkWhitePrimary;
  border-radius: 1.25rem;
  gap: 4rem;
  justify-content: center;
  .advantages-card--item {
    display: flex;
    align-items: center;
    gap: 0.88rem;
    .advantages-card--item--number {
      color: $redPrimary;
      font-size: 2.25rem;
      font-weight: 700;
    }
    .advantages-card--item--title {
      max-width: 6.3125rem;
      font-size: 1rem;
    }
  }
}

.advantages-card-big, .advantages-card-small {
  border: 1px solid rgba(255, 255, 255, 0.0);
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid $redPrimary;
  }
}

.advantages-card-small {
  &.card--grey-icon:before {
    content: unset;
  }
}

@media only screen and (max-width: 1630px){
  .advantages {
  }

  .advantages-card {
  }

  .advantages-card-medium {
    .advantages-card--title {
    }
    .advantages-card--button {
      .icon--arrow-right-white {
      }
    }
  }

  .advantages-card-big {
    gap: 3rem;
    .advantages-card--item {
      .advantages-card--item--title {
        max-width: 5.9rem;
      }
    }
  }

  .advantages-card-small {
  }
}

@media only screen and (max-width: 1530px){
  .advantages {
  }

  .advantages-card {
  }

  .advantages-card-medium {
    .advantages-card--title {
    }
    .advantages-card--button {
      .icon--arrow-right-white {
      }
    }
  }

  .advantages-card-big {
    gap: 2rem;
  }

  .advantages-card-small {
  }
}

@media only screen and (max-width: 1500px){
  .advantages {
    //display: flex;
    //flex-direction: column;
    //padding-bottom: 3rem;
  }

  .advantages-card {
    .advantage-card--item--title {
      font-size: .8rem;
    }
  }

  .advantages-card-medium {
    //padding: 1.5rem;
    //gap: 1.5rem;
    .advantages-card--title {
      font-size: 1.5rem;
    }
    .advantages-card--button {
      .icon--arrow-right-white {
      }
    }
  }

  .advantages-card-big {
    .advantages-card--item {
      .advantages-card--item--number {
        font-size: 1.7rem;
      }
      .advantages-card--item--title {
        font-size: .8rem;
      }
    }
  }

  .advantages-card-small {
    //gap: 3.75rem;
  }
}

@media only screen and (max-width: 1400px){
  .advantages {
    //display: flex;
    //flex-direction: column;
    //padding-bottom: 3rem;
  }

  .advantages-card {
    .advantage-card--item--title {
      font-size: .8rem;
    }
  }

  .advantages-card-medium {
    //padding: 1.5rem;
    //gap: 1.5rem;
    .advantages-card--title {
      font-size: 1.5rem;
    }
    .advantages-card--button {
      .icon--arrow-right-white {
      }
    }
  }

  .advantages-card-big {
    gap: 1.5rem;
    .advantages-card--item {
      .advantages-card--item--number {
        font-size: 1.5rem;
      }
      .advantages-card--item--title {
        font-size: .8rem;
      }
    }
  }

  .advantages-card-small {
    //gap: 3.75rem;
  }
}

@media only screen and (max-width: 1300px){
  .advantages {
    //display: flex;
    //flex-direction: column;
    //padding-bottom: 3rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .advantages-card {
    .advantage-card--item--title {
      font-size: .7rem;
    }
  }

  .advantages-card-medium {
    //padding: 1.5rem;
    //gap: 1.5rem;
    padding: 2rem 1.3rem 2rem;
    .advantages-card--title {
      font-size: 1.3rem;
    }
    .advantages-card--button {
      font-size: .7rem;
      white-space: nowrap;
      .icon--arrow-right-white {
      }
    }
  }

  .advantages-card-big {
    gap: .5rem;
    padding-top: 3.06rem;
    padding-bottom: 3.06rem;
    .advantages-card--item {
      gap: .5rem;
      .advantages-card--item--number {
        font-size: 1.3rem;
      }
      .advantages-card--item--title {
        font-size: .7rem;
      }
    }
  }

  .advantages-card-small.card--grey-icon {
    padding: 1.08rem 1.01rem 1.07rem;
    .icon {
      width: 2rem;
      height: 2rem;
    }
  }
}

@media only screen and (max-width: 1240px){
  .advantages {
  }
  .advantages-card {
    .advantage-card--item--title {
    }
  }
  .advantages-card-medium {
    .advantages-card--title {
    }
    .advantages-card--button {
      .icon--arrow-right-white {
      }
    }
  }
  .advantages-card-big {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: row;
    gap: 20px;
    justify-content: space-evenly;
    .advantages-card--item {
      .advantages-card--item--number {
      }
      .advantages-card--item--title {
      }
    }
  }
  .advantages-card-small {
  }
}
@media only screen and (max-width: 980px){
  .advantages {
    display: flex;
    width: 100%;
    //flex-direction: row;
    padding-bottom: 3rem;
    flex-wrap: wrap;
    .advantages-card:nth-child(1) { grid-area: unset; }
    .advantages-card:nth-child(2) { grid-area: unset; }
    .advantages-card:nth-child(3) { grid-area: unset; }
    .advantages-card:nth-child(4) { grid-area: unset; }
    .advantages-card:nth-child(5) { grid-area: unset; }
    .advantages-card:nth-child(6) { grid-area: unset; }
    .advantages-card:nth-child(7) { grid-area: unset; }
  }

  .advantages-card {

  }

  .advantages-card-medium {
    max-width: 20%;
    gap: 1rem;
    .advantages-card--title {
      font-size: 1rem;
      max-width: 8.18rem;
    }
    .advantages-card--button {
      .icon--arrow-right-white {
      }
    }
  }

  .advantages-card-big {
    max-width: 78%;
    width: 100%;
    //display: flex;
    //flex-direction: column;
    padding-top: 2.12rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    gap: 2.06rem;
    justify-content: space-evenly;
    //gap: 1.5rem;
    .advantages-card--item {
      .advantages-card--item--number {
        //font-size: 2rem;
      }
      .advantages-card--item--title {
        //font-size: 0.875rem;
      }
    }
  }

  .advantages-card-small {
    width: 32%;
    gap: 1rem;
  }
}
@media only screen and (max-width: 880px){
  .advantages {
    .advantages-card:nth-child(1) { grid-area: unset; }
    .advantages-card:nth-child(2) { grid-area: unset; }
    .advantages-card:nth-child(3) { grid-area: unset; }
    .advantages-card:nth-child(4) { grid-area: unset; }
    .advantages-card:nth-child(5) { grid-area: unset; }
    .advantages-card:nth-child(6) { grid-area: unset; }
    .advantages-card:nth-child(7) { grid-area: unset; }
  }

  .advantages-card {

  }

  .advantages-card-medium {
    width: 100%;
    max-width: unset;
    .advantages-card--title {
      font-size: 1rem;
      max-width: 8.18rem;
    }
    .advantages-card--button {
      .icon--arrow-right-white {
      }
    }
  }

  .advantages-card-big {
    width: 100%;
    max-width: unset;
    gap: .5rem;
    .advantages-card--item {
      .advantages-card--item--number {
      }
      .advantages-card--item--title {
      }
    }
  }

  .advantages-card-small {
    width: 100%;
    max-width: unset;
  }
}
@media only screen and (max-width: 680px){
  .advantages {
  }

  .advantages-card {
  }

  .advantages-card-medium {
    .advantages-card--title {
    }
    .advantages-card--button {
      .icon--arrow-right-white {
      }
    }
  }

  .advantages-card-big {
    display: flex;
    flex-direction: column;
    padding-top: 2.12rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    gap: 2.06rem;
    .advantages-card--item {
      .advantages-card--item--number {
        font-size: 2rem;
      }
      .advantages-card--item--title {
        font-size: 0.875rem;
      }
    }
  }

  .advantages-card-small {
    width: 100%;
    max-width: unset;
  }
}
