.catalog-card-grid {
  //display: flex;
  gap: 1.69rem;
  display: grid;
  grid-template-columns: repeat(5, 17rem);
  grid-auto-rows: 17rem;
  padding-bottom: 1.88rem;
  .catalog-card-item {
   .catalog-card-item--title {
     font-weight: 700;
     max-width: 12.5rem;
   }
  }
}

@media only screen and (max-width: 1553px) {
  .catalog-card-grid {
    grid-template-columns: repeat(5, 15rem);
    grid-auto-rows: 15rem;
  }
}

@media only screen and (max-width: 1833px) {
  .catalog-card-grid {
    grid-template-columns: repeat(4, 15rem);
    grid-auto-rows: 15rem;
  }
}

@media only screen and (max-width: 1114px) {
  .catalog-card-grid {
    grid-template-columns: repeat(3, 15rem);
    grid-auto-rows: 15rem;
  }
}

@media only screen and (max-width: 820px) {
  .catalog-card-grid {
    grid-template-columns: repeat(2, 15rem);
    grid-auto-rows: 15rem;
  }
}

@media only screen and (max-width: 550px) {
  .catalog-card-grid {
    grid-template-columns: 1fr;
    grid-auto-rows: unset;
    //grid-auto-rows: 15rem;
    .catalog-card-item {
      i {
        margin-right: 1rem;
      }
      .catalog-card-item--title {
        font-size: .875rem;
        max-width: 9.75rem;
      }
    }
  }
}