.ready-solutions-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 1.88rem;
  padding-bottom: 1.88rem;
}

@media only screen and (max-width: 1170px) {
  .ready-solutions-card-grid {
    grid-template-columns: repeat(3,1fr);
  }
}

@media only screen and (max-width: 850px) {
  .ready-solutions-card-grid {
    grid-template-columns: repeat(2,1fr);
  }
}

@media only screen and (max-width: 623px) {
  .ready-solutions-card-grid {
    grid-template-columns: repeat(1,1fr);
    gap: 1.38rem;
  }
}