/* The Modal (background) */
.contact-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  /* Modal Content/Box */
  .contact-modal--content {
    margin: 15% auto; /* 15% from the top and centered */
    width: 50%; /* Could be more or less, depending on screen size */
    position: relative;
    /* The Close Button */
    .contact-modal--close {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #aaa;
      margin-right: 20px;
      font-size: 28px;
      font-weight: bold;
      &:hover,
      &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  /* The Modal (background) */
  .contact-modal {
    /* Modal Content/Box */
    .contact-modal--content {
      width: 70%;
      /* The Close Button */
      .contact-modal--close {
        &:hover,
        &:focus {
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  /* The Modal (background) */
  .contact-modal {
    /* Modal Content/Box */
    .contact-modal--content {
      width: 90%;
      /* The Close Button */
      .contact-modal--close {
        right: 5px;
        top: 5px;
        &:hover,
        &:focus {
        }
      }
    }
  }
}