@import "./base/helpers";
@import "./global/colors";
.home-slider {
  position: relative;
  overflow: hidden;
  max-height: 37.5rem;
  .home-slider--wrapper {
    .home-slider--item {
      display: flex;
      //height: auto !important;
      .home-slider--item-left {
        padding: 3.25rem 2.12rem 5.56rem 4.38rem;
        flex: 6;
        background-color: $redPrimary;
        .home-slider--item-subtitle {
          display: inline-block;
          border-radius: 1.25rem;
          background: #FFF;
          padding: 0.44rem 0.88rem;
          margin-bottom: 9.62rem;
        }
        .home-slider--item-title {
          max-width: 40rem;
          margin-bottom: 0.62rem;
        }
        .home-slider--item-body {
          margin-bottom: 3rem;
          max-width: 40rem;
          font-size: 1.25rem;
          line-height: 120%; /* 1.5rem */
          color: $whitePrimary;
        }
        .home-slider--item-button {
          display: inline-flex;
          padding: 1rem 2.25rem;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          border-radius: 0.5rem;
          background: $darkWhitePrimary;
        }
      }
      .home-slider--item-right {
        flex: 4;
        .home-slider--item-bgc {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .home-slider-pagination {
    position: absolute;
    right: 2px;
    left: 50%;
    top: 50%;
    //transform: translate3d(0px,-60%,60%);
    transform: translate(23%, 0);
    .swiper-pagination-bullet {
      width: 2px;
      height: 30px;
      opacity: .5;
      //background: rgba(255, 255, 255, 1);
      border-radius: unset;
      background: linear-gradient(180deg, #fff 50%, rgba(255,255,255, 0.5) 50%);
      background-size: 100% 200%;
      background-position: bottom;
      transition: all .3s ease-out;
    }
    .swiper-pagination-bullet-active{
      opacity: 1;
      background-position: top;
    }
  }
  @keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }

    to {
      stroke-dashoffset: 0;
    }
  }
}
@media only screen and (max-width: 1240px) {
  .home-slider {
    .home-slider--wrapper {
      .home-slider--item {
        flex-direction: column-reverse;
        position: relative;
        .home-slider--item-left {
          padding-top: 1.87rem;
          padding-bottom: 1.75rem;
          @include baseRootLeftRightPaddingsM();
          .home-slider--item-subtitle {
            position: absolute;
            top: 1.44rem;
          }
          .home-slider--item-title {
          }
          .home-slider--item-body {
            font-size: 0.875rem;
          }
          .home-slider--item-button {
          }
        }
        .home-slider--item-right {
          max-height: 15rem;
          .home-slider--item-bgc {
          }
        }
      }
    }
    .home-slider-pagination {
      right: var(--swiper-pagination-right,8px);
      left: var(--swiper-pagination-left,auto);
      top: 50%;
      transform: translate3d(0px,-50%,0);
      .swiper-pagination-bullet {
      }
      .swiper-pagination-bullet-active{
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .home-slider {
    max-height: 30rem;
    .home-slider--wrapper {
      .home-slider--item {
        .home-slider--item-left {
          @include baseRootLeftRightPaddingsM();
          .home-slider--item-subtitle {
          }
          .home-slider--item-title {
          }
          .home-slider--item-body {
          }
          .home-slider--item-button {
          }
        }
        .home-slider--item-right {
          .home-slider--item-bgc {
          }
        }
      }
    }
    .home-slider-pagination {
      top: 65%;
      transform: translate3d(0px,-65%,0);
      .swiper-pagination-bullet {
      }
      .swiper-pagination-bullet-active{
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 963px) {
  .home-slider {

  }
}