.submit-your-application {
  display: flex;
  flex-direction: column;
  gap: 3.06rem;
  border-radius: 1.25rem;
  background: $darkWhitePrimary;
  padding: 1.88rem 4.75rem 4.06rem;

  .submit-your-application--title {
    font-size: 1.625rem;
    font-weight: 700;
    text-align: center;
  }

  .submit-your-application--form {
    display: flex;
    flex-direction: column;
    gap: 1.12rem;

    .submit-your-application--form-row {
      label {
        input {

        }
      }
    }

    .submit-your-application--send {

    }
  }
}

@media only screen and (max-width: 1440px) {
  .submit-your-application {
    padding: 1.88rem 2.75rem 2.06rem;
  }
}

@media only screen and (max-width: 1240px) {
  .submit-your-application {
    padding: 1.33rem 1.75rem 1.56rem;
  }
}

@media only screen and (max-width: 450px) {
  .submit-your-application {
    //padding: 1rem 1rem 1rem;
    gap: 2.06rem;
    .submit-your-application--title {
      font-size: 1.2rem;
    }
  }
}