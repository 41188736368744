@import "../scss/global/colors";

.portfolio--wrapper {
  padding-bottom: 8.13rem;
  .portfolio-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 23rem;
    gap: 1.87rem;
  }
  .portfolio-footer {

  }
}

.portfolio-card {
  border-radius: 1.25rem;
  padding-bottom: 4.06rem;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 2rem;
  position: relative;
  overflow: hidden;
  background-size: cover;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 100%);
  }
  .portfolio-card--title {
    max-width: 33rem;
    color: $whitePrimary;
    z-index: 2;
    position: relative;
  }
  .portfolio-card--button {
    z-index: 2;
    position: relative;
    padding: .75rem 1rem;
    //display: none;
  }
  /*&:nth-child(odd) {
    .portfolio-card--button {
      display: none;
    }
  }*/
  &:nth-child(2n+1) {
    .portfolio-card--button {
      //display: block;
    }
  }
}

@media only screen and (max-width: 980px) {
  .portfolio--wrapper {
    .portfolio-body {
      grid-auto-rows: 18rem;
      gap: 1.87rem;
    }
    .portfolio-footer {

    }
  }
}

@media only screen and (max-width: 900px) {
  .portfolio--wrapper {
    .portfolio-body {
      grid-auto-rows: 16rem;
      gap: 1.87rem;
    }
    .portfolio-footer {

    }
  }
  .portfolio-card {
    padding-bottom: 2.06rem;
  }
}

@media only screen and (max-width: 780px) {
  .portfolio--wrapper {
    .portfolio-body {
      grid-template-columns: 1fr;
      grid-auto-rows: 10.3rem;
      gap: 1.37rem;
    }
    .portfolio-footer {

    }
  }
  .portfolio-card {
    padding-bottom: 1.5rem;
    padding-left: 1.12rem;
    padding-right: 1.12rem;
    background-position: center;
    background-size: cover;
    .portfolio-card--title {
      font-size: 0.875rem;
    }
    .portfolio-card--button {
      display: none;
    }
  }
}