@import "./global/colors";

.searchbar {
  padding-top: 1.19rem;
  padding-bottom: 1.19rem;
  //height: 3.0625rem;
  position: absolute;
  z-index: 999;
  //display: none;
  width: 100%;
  //margin-top: .8rem;
  background: #f7f7fa;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  &.active {
    //display: block;
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
}

form.searchbar--form {
  height: 3.0625rem;
  position: relative;
  max-width: 70%;
  margin: 0 auto;
  .searchbar-send {
    display: block;
    position: absolute;
    left: 1.13rem;
    z-index: 2;
    top: 20%;
    width: fit-content;
    .searchbar-input--icon {
      content: url("/img/icons/search-light-grey.svg");
      display: block;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  label {
    position: absolute;
    width: 100%;
    .searchbar-input {
      width: 100%;
      padding-top: 0.81rem;
      padding-bottom: 0.81rem;
      padding-left: 3.78rem;
      border-radius: 0.5rem;
      border: 1.5px solid $lightGrayPrimary;
      color: $blackPrimary;
      &::placeholder {
        color: $blackPrimary;
      }
      &:focus {
        outline-color: $blackPrimary;
      }
    }
  }

  .searchbar-reset {
    z-index: 2;
    display: block;
    position: absolute;
    right: 1rem;
    top: 20%;
    .searchbar-send--enter {
      display: none;
      &.active {
        display: block;
      }
    }
    .searchbar-send--reset {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 620px){
  .searchbar {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  form.searchbar--form {
    max-width: 100%;
    .searchbar-send {
      left: 0.5rem;
      top: 15%;
      .searchbar-input--icon {
      }
    }
    label {
      .searchbar-input {
        padding-left: 2.78rem;
        &::placeholder {
        }
      }
    }

    .searchbar-reset {
      right: 0.5rem;
      top: 15%;
      .searchbar-send--enter {
      }
      .searchbar-send--reset {
      }
    }
  }
}