.template-detail--wrapper {
  padding-top: 5.87rem;
  padding-bottom: 8.13rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.87rem;
  overflow-x: hidden;
}
@media only screen and (max-width: 1240px) {
  .template-detail--wrapper {
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    gap: 1.87rem;
  }
}