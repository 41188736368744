@import "./global/colors";

.blog-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  transition: all 1s ease;
  gap: 1rem;

  .blog-card--img {
    border-radius: 1.25rem;
    width: 100%;
    height: 237px;
    //height: auto;
    //max-height: 237px;
  }

  .blog-card-body {
    //padding-top: 1.5rem;
    //padding-bottom: 1.5rem;
  }

  .blog-card-footer {
    display: flex;
    flex-direction: column;
    //gap: 1.5rem;
  }

  .blog-card--title {
    font-size: 1.25rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .blog-card--body {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .blog-card--date {
    font-weight: 400;
    color: $darkGrayPrimary;
  }

  .button-read-show {
    display: none;
  }

  &.active {
    position: relative;

    .blog-card-head {
      position: relative;
      border-radius: 1.25rem;overflow: hidden;
      &::before {
        backdrop-filter: blur(20px);
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
      }
      .blog-card--img {
      }
      .button-read-show {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 6.94rem;
        margin-right: 6.94rem;
        padding: 0.75rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon--read-white {
          margin-left: 1rem;
        }

        &:hover {
          background: $redPrimary;
          color: $whitePrimary;
          .icon--read-white {
            margin-left: 1rem;
            //background-image: url("/img/icons/gallery-red.svg");
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1475px){
  .blog-card {
    &.active {
      .blog-card-head {
        .button-read-show {
          margin-left: 4.5rem;
          margin-right: 4.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px){
  .blog-card {
    .blog-card--title {
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 680px){
  .blog-card {
    flex-shrink: 0;
    width: 100%;
    gap: 0.5rem;
  }
}