@import "../base/helpers";
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 16px;
  font-family: Montserrat, serif;
}

html,
body {
  min-height: 100%;
  position: relative;
  width: 100%;
}

body {
  font-size: 1rem;
  width: 100%;
}

p {
  color: #33363c;
  font-family: Montserrat,serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;

}
h2 {
  font-family: Montserrat,serif;
}

ol, ul, table {
  color: #33363c;
  font-family: Montserrat,serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

li, td, tr, tbody {
  /* 		padding-top: 2.25rem !important; */
  color: #33363c;
  font-family: Montserrat,serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul, li, ol {
  list-style-type: none;
  padding: unset;
}

a {
  text-decoration: unset;
  color: unset;
}

form {
  margin: unset;
}

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.--just-space {
  justify-content: space-between;
}

.--just-center {
  justify-content: center;
}

.--just-end {
  justify-content: flex-end;
}

.--align-str {
  align-items: stretch;
}

.--align-center {
  align-items: center;
}

.--align-end {
  align-items: flex-end;
}

.--dir-col {
  flex-direction: column;
}

.root-paddings-l-r {
  @include baseRootLeftRightPaddings();
}

.root-paddings-t-b {
  @include baseRootTopBottomPaddings();
}

.root-paddings-b {
  padding-bottom: 8.88rem;
}

@media only screen and (max-width: 1800px){
  .root-paddings-l-r {
    @include baseRootLeftRightPaddingsT();
  }
}
@media only screen and (max-width: 980px){
  .root-paddings-l-r {
    @include baseRootLeftRightPaddingsM();
  }
  li, ol, p, table, tbody, td, tr, ul {
    font-size: 12px;
  }
}