@import "./global/colors";
.home-blog {
  padding-top: 8rem;
  padding-bottom: 7.5rem;
  .home-blog--wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.38rem;
    .home-blog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .home-blog-header--title {
        font-size: 1.625rem;
        font-weight: 700;
      }
      .home-blog-header--button-link {
        width: fit-content;
        display: flex;
        align-items: center;
        .icon--arrow-right--red {
          padding-left: 1rem;
        }
      }
      .home-blog-header--mobile-control {
        display: none;
        gap: 0.87rem;
        align-items: center;
        span {
          cursor: pointer;
        }
      }
    }
    .home-blog-body {
      display: flex;
      flex-wrap: nowrap;
      gap: 1.81rem;
      position: relative;
      /*.home-blog-slider-panel-control {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
      }*/
      .home-blog-slider-panel-control--left, .home-blog-slider-panel-control--right {
        position: absolute;
        top: 25%;
        z-index: 2;
        cursor: pointer;
      }
      .home-blog-slider-panel-control--left {
        left: 0;
        transform: translateX(-50%);
      }
      .home-blog-slider-panel-control--right {
        right: 0;
        transform: translateX(50%);
      }
    }
  }
}

@media only screen and (max-width: 980px){
  .home-blog {
    .home-blog--wrapper {
      .home-blog-header {
        .home-blog-header--title {
        }
        .home-blog-header--button-link {
          .icon--arrow-right--red {
          }
        }
      }
      .home-blog-body {
        gap: 1.5rem;
      }
    }
  }
}

@media only screen and (max-width: 680px){
  .home-blog {
    padding-top: 3rem;
    .home-blog--wrapper {
      .home-blog-header {
        .home-blog-header--title {
        }
        .home-blog-header--button-link {
          display: none;
          .icon--arrow-right--red {
          }
        }
        .home-blog-header--mobile-control {
          display: flex;
        }
      }
      .home-blog-body {
        //display: grid;
        //grid-template-columns: repeat(auto-fill, 1fr);
        overflow-y: auto;
      }
    }
  }
}

@media only screen and (max-width: 680px){
  .home-blog {
    .home-blog--wrapper {
      .home-blog-header {
        .home-blog-header--title {
        }
        .home-blog-header--button-link {
          .icon--arrow-right--red {
          }
        }
        .home-blog-header--mobile-control {
        }
      }
      .home-blog-body {
        .home-blog-slider-panel-control {
          display: none;
        }
        .home-blog-slider-panel-control--left, .home-blog-slider-panel-control--right {
          display: none;
        }
      }
    }
  }
}