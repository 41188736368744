@mixin baseRootLeftRightPaddings() {
  padding-left:4.375rem;
  padding-right:4.375rem;
}
@mixin baseRootLeftRightPaddingsT() {
  padding-left:2.25rem;
  padding-right:2.25rem;
}
@mixin baseRootLeftRightPaddingsM() {
  padding-left:1.25rem;
  padding-right:1.25rem;
}
@mixin baseRootTopBottomPaddings() {
}
@mixin baseIconSize() {
  width: 1.5rem;
  height: 1.5rem;
}