@import './colors.scss';
//#TODO Settings
$path: '/fonts';
//#TODO Settings-End

//#TODO Fonts
.base-text {
  font-family: Montserrat, serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: $blackPrimary;
}
.big-text {
  color: #33363C;
  font-family: Montserrat, serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.small-text {
  font-family: Montserrat, serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: $blackPrimary;
}
.h1 {
  color: $whitePrimary;
  font-family: Montserrat, serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 3.6rem */
}

.h3 {
  color: #33363C;
  font-family: Montserrat, serif;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media only screen and (max-width: 1100px){
  .h1 {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 980px){
  .base-text {
    font-size: .75rem;
  }
  .h3 {
    font-size: 1.25rem;
  }
}
//#TODO Fonts-End

//#TODO Fonts-methods
@mixin font-face-generator($font-family, $file-path, $weight: normal, $style: normal) {
  //@include font-face($font-family, $file-path, $weight, $style, eot woff2 woff);
  @font-face {
    font-family: $font-family;
    font-style: $style;
    font-weight: $weight;
    src: url('#{$file-path}/#{$font-family}.eot') format('eot'),
    url('#{$file-path}/#{$font-family}.woff2') format('woff2'),
    url('#{$file-path}/#{$font-family}.woff') format('woff'),
    url('#{$file-path}/#{$font-family}.ttf') format('truetype');
  }
}
//#TODO Fonts-methods-End