@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/base/_01_normalize.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/base/_02_swiper.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/base/_mixins.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/base/helpers.scss";

@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/_global.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/_svg.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/_typography.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/buttons.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/colors.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/header.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/icons.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/inputs.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/logo.scss";

@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/about-head/about-head.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/accordion/accordion.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/breadcrumbs/breadcrumbs.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/cards/card--bgc.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/cards/card--grey-icon.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/catalog-cards/catalog-cards.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/catalog-category-body/catalog-category-body.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/catalog-category-sidebar/catalog-category-sidebar.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/contact-internal/contact-internal.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/contact-us-block/contact-us-block.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/page-title/page-title.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/pagination/pagination.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/product-card/product-card.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/product-detail/product-detail.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/product-gallery/product-gallery.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/product-info/product-info.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/product-overview/product-overview.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/ready-solutions-cards/ready-solutions-cards.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/submit-your-application/submit-your-application.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/template-detail-header/template-detail-header.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/template-detail-list/template-detail-list.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/template-detail-product-slider/template-detail-product-slider.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/template-detail-table/template-detail-table.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/template-detail-text-image/template-detail-text-image.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/template-detail-text/template-detail-text.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/template-detail/template-detail.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/template-sidebar/template-sidebar.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/components/bem-blocks/traktor/traktor.scss";

//@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/_fonts.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/_local-fonts.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/base/_01_normalize.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/base/_02_swiper.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/base/_mixins.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/_global.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/_svg.scss";
@import "/Users/andrejulanov/Projects/geek-flow/gugol/content/src/scss/global/_typography.scss";



.privacy-policy-text {
  color: $darkGrayPrimary;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  a {
    color: $redPrimary;
    font-size: 0.875rem;
  }
}