@import "../../../scss/global/colors";

.contact-internal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: $redPrimary;
  padding-top: 6.63rem;
  padding-bottom: 5.25rem;
  gap: 11.31rem;

  .contact-internal--left {
    max-width: 35.75rem;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;

    .contact-internal--title {
      font-size: 1.625rem;
      font-weight: 700;
      color: $whitePrimary;
    }

    .contact-internal--body {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      p {
        font-size: 1.25rem;
        line-height: 140%; /* 1.75rem */
        color: $whitePrimary;
      }
    }
  }

  .contact-internal--right {
    max-width: 26.375rem;
  }
}

.contact-internal--form {
  display: flex;
  flex-direction: column;
  gap: 1.13rem;
  .contact-internal--form-row {
    display: flex;
    gap: 1.13rem;
    justify-content: center;
    color: $whitePrimary;
    label {
      input {
        color: $whitePrimary;
      }
      textarea {
        height: 7.8125rem;
        color: $whitePrimary;
      }
    }
    .contact-internal--name--label {
      flex: 5;
    }
    .contact-internal--phone--label {
      flex: 5;
    }
    .contact-internal--message--label {
      flex: 10;
    }
  }
  .contact-internal--send {
    color: $redPrimary;
  }
  .contact-internal--privacy-policy {
    color: $whitePrimary;
    a {
      color: $whitePrimary;
    }
  }
}

@media only screen and (max-width: 1330px){
  .contact-internal {
    gap: 5.31rem;
    .contact-internal--left {
      max-width: 27.75rem;
    }
  }
}

@media only screen and (max-width: 1150px){
  .contact-internal {
    gap: 3.31rem;
  }
}

@media only screen and (max-width: 1150px){
  .contact-internal {
    gap: 3.31rem;
  }
}

@media only screen and (max-width: 980px){
  .contact-internal {
    flex-direction: column;
    gap: 1.9375rem;
    padding: 1.75rem 1.25rem;

    .contact-internal--left {
      gap: 1rem;
      .contact-internal--title {
        font-size: 1.25rem;
      }
      .contact-internal--body {
        gap: 1rem;
        p {
          font-size: .75rem;
        }
      }
    }
    .contact-internal--right {
    }
  }
  .contact-internal--form {
    .contact-internal--form-row {
      flex-direction: column;
      label {
        input {
        }
        textarea {
        }
      }
      .contact-internal--name--label {
      }
      .contact-internal--phone--label {
      }
      .contact-internal--message--label {
      }
      .contact-internal--send {
      }
    }
    .contact-internal--privacy-policy {
      font-size: 0.625rem;
      a {
        font-size: 0.625rem;
      }
    }
  }
}