@import "../../../scss/global/colors";

.catalog-category-sidebar {
  max-width: 17rem;
  width: 100%;

  .catalog-category-sidebar--toggle-mobile {
    display: none;
  }
}

.catalog-filter {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .catalog-filter-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.catalog-filter-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .catalog-filter-block--title {
    color: $blackPrimary;
    font-size: 1.125rem;
    font-weight: 700;
  }
}

.catalog-filter-block--categories {
  display: flex;
  gap: 0.87rem;
  flex-direction: column;

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 1.74500rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.125rem;
      width: 1.125rem;
      border: 1px solid $lightGrayPrimary;
      border-radius: 4px;
    }

    &:hover input ~ .checkbox-checkmark {
      background-color: $redPrimary;
      border-color: $redPrimary;
    }

    input:checked ~ .checkbox-checkmark {
      background-color: $redPrimary;
      border-color: $redPrimary;
    }

    .checkbox-checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkbox-checkmark:after {
      display: block;
    }

    .checkbox-checkmark:after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.catalog-filter-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 980px) {
  .catalog-category-sidebar {
    max-width: unset;
  }
}

@media only screen and (max-width: 830px) {
  .catalog-category-sidebar {
    .catalog-category-sidebar--toggle-mobile {
      display: flex;
      font-size: .875rem;
      align-items: center;
      gap: .38rem;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
  .catalog-filter {
    display: none;
  }
  .catalog-category-sidebar{
    &.active {
      .catalog-filter {
        display: flex;
      }
    }
  }
}