@import "../../../scss/global/colors";
.template-detail {
  grid-column-start: 1;
  grid-column-end: 9;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media only screen and (max-width: 980px) {
  .template-detail {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 13;
  }
}