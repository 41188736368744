@import 'colors';
@import '../base/helpers';
//Default
button {
  border: unset;
  cursor: pointer;
  background: unset;
  padding: unset;
}
.catalog-button {
  display: inline-flex;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1.5px solid $redPrimary;
  color: $redPrimary;
  cursor: pointer;
}
.catalog-button.active {
  color: $whitePrimary;
  background-color: $redPrimary;
}
.button-base--red {
  background: $redPrimary;
  padding: 1rem 2.25rem;
  text-align: center;
  color: $whitePrimary;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: $whitePrimary;
    color: $redPrimary;
  }
}

.button-base--white {
  border-radius: 0.5rem;
  background: $whitePrimary;
  padding: 1rem 2.25rem;
  text-align: center;
  color: $blackPrimary;
  cursor: pointer;
}
.button-border--white {
  color: $whitePrimary;
  border-radius: 0.5rem;
  border: 1px solid $whitePrimary;
  display: inline-flex;
  padding: 0.75rem 1rem;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    color: $redPrimary;
    background-color: $whitePrimary;
  }
}
.button-border--red {
  color: $redPrimary;
  border-radius: 0.5rem;
  border: 1px solid $redPrimary;
  //display: inline-flex;
  padding: 0.75rem 1rem;
  //justify-content: flex-start;
  //align-items: center;
  text-align: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: rgba(255,255,255,1);
    background-color: $whitePrimary;
  }
}
//Default-End

@media only screen and (min-width: 320px) and (max-width: 1240px){
  .button-base--red {
    background: $redPrimary;
    padding: 1rem 0.5rem;
    text-align: center;
    color: $whitePrimary;
    border-radius: 0.5rem;
  }
}