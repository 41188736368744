@import "./global/colors";

.contacts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.87rem;
  padding-bottom: 8.13rem;
}

.contact-card {
  background-color: $darkWhitePrimary;
  padding: 1.38rem 1.56rem 1.62rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.13rem;
  border-radius: 1.25rem;
  height: 100%;

  .contact-card--header {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.87rem;
  }

  .contact-card--body {
    .contact-card--body-row {
     .contact-card--body-col {
     }
    }
    .contact-address-block {
      .phone-base {
        .phone-base--title {
          color: #000000;
        }

        .phone-base--subtitle {
          color: rgba(0, 0, 0, 0.22);
        }

        .phone-base--link {
          color: #000000;
        }
      }
      .email-base {
        .email-base--title {
          color: #000000;
        }
        .email-base--link {
          color: #000000;
        }
      }
    }

    .contact-addresses-menu {
      .contact-address-menu-item {
        .contact-address-menu-item-city {
          color: #000000;
        }

        .contact-address-menu-item-address {
          color: #000000;
        }

        .contact-address-menu-item-phone {
          color: #000000;
        }
      }
    }
  }
}

.contacts-tile {
  .contact-card--body {
    .contact-card--body-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom: 2.19rem;
      &:last-of-type {
        padding-bottom: unset;
      }
      .contact-card--body-col {

      }
    }
  }
}

.lk-tile {
  .contact-card--body {
    .contact-card--body-row {
      grid-template-columns: 1fr;
      .contact-card--body-col {
        display: flex;
        flex-direction: column;
        gap: .75rem;
      }
    }
  }
}

.contact-card--list {
  display: flex;
  flex-direction: row;
  .contact-card--list-item {
    max-width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .37rem;
    &:before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: red; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: block; /* Needed to add space between the bullet and the text */
      width: 0.4375rem;
      height: 0.4375rem;
      //margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }
}


.lk-button.button-border--red {
  display: flex;
  align-items: center;
  width: fit-content;
  i {
    margin-left: 1rem;
  }
}

.downloads-tile {
  .contact-card--body {
    .contact-card--body-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .contact-card--body-col {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        max-width: 11.68rem;
        gap: 1rem;
        .program-icon {

        }
        .program-title{
          font-weight: 400;
        }
        .program-buttons {
          display: flex;
          gap: .75rem;
          .program-button {
            display: flex;
            align-items: center;
            width: fit-content;
            .icon-receive-red {
              margin-left: .5rem;
            }
          }
        }
        .program-description {
          font-size: .75rem;
          font-weight: 400;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px){
  .downloads-tile {
    .contact-card--body {
      .contact-card--body-row {
        .contact-card--body-col {
          .program-icon {

          }
          .program-title{
          }
          .program-buttons {
            .program-button {
              padding: .75rem;
              i {
                width: 1.125rem;
                height: 1.125rem;
                object-fit: fill;
              }
            }
          }
          .program-description {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px){
  .downloads-tile {
    .contact-card--body {
      .contact-card--body-row {
        grid-template-columns: 1fr 1fr;
        .contact-card--body-col {
          .program-icon {

          }
          .program-title{
          }
          .program-buttons {
            .program-button {
              i {
              }
            }
          }
          .program-description {
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px){
  .contacts-grid{
    grid-template-columns: 1fr;
  }
  .downloads-tile {
    .contact-card--body {
      .contact-card--body-row {
        grid-template-columns: 1fr 1fr 1fr;
        .contact-card--body-col {
          .program-icon {

          }
          .program-title{
          }
          .program-buttons {
            .program-button {
              i {
              }
            }
          }
          .program-description {
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 580px){
  .contacts-grid{
  }
  .downloads-tile {
    .contact-card--body {
      .contact-card--body-row {
        grid-template-columns: 1fr 1fr;
        .contact-card--body-col {
          .program-icon {

          }
          .program-title{
          }
          .program-buttons {
            .program-button {
              i {
              }
            }
          }
          .program-description {
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px){
  .contacts-grid{
  }
  .contact-card{
    gap: 1.12rem;
    .contact-card--header {
      gap: 1.12rem;
    }
  }
  .contacts-tile {
    .contact-card--body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .contact-card--body-row {
        padding-bottom: unset;
        grid-template-columns: 1fr;
        gap: 1rem;
        .contact-card--body-col {

        }
      }
    }
  }
  .contact-card--list {
    flex-direction: column;
    gap: 1rem;
    .contact-card--list-item {
      max-width: 100%;
    }
  }
  .downloads-tile {
    .contact-card--body {
      .contact-card--body-row {
        grid-template-columns: 1fr;
        gap: 2.25rem;
        .contact-card--body-col {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          max-width: unset;
          align-items: center;
          justify-content: flex-start;
          .program-icon {
            width: 30%;
          }
          .program-title{
            width: 60%;
          }
          .program-buttons {
            width: 100%;
            .program-button {
              &:first-of-type {
                width: 100%;
                justify-content: space-between;
              }
              i {
              }
            }
          }
          .program-description {
            width: 100%;
          }
        }
      }
    }
  }
  .lk-button.button-border--red {
    width: 100%;
    justify-content: space-between;
  }
}