@import "colors";
@import "../base/helpers";
input, textarea {
  border: unset;
}

.base-input--gray {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $lightGrayPrimary;
  background-color: unset;
  width: 100%;
  &::placeholder {
    color: $blackPrimary;
  }
}

.base-input--white {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid $whitePrimary;
  background-color: unset;
  width: 100%;
  &::placeholder {
    color: $whitePrimary;
  }
}