@import "./base/helpers";
.result--wrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;

  .result-head {
    text-align: center;
    padding-bottom: 3rem;
  }

  .result-body--block {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 2.37rem;
    .page-title {
      padding-left: unset;
      padding-bottom: 1.75rem;
    }
    &:nth-child(2) {
      padding-top: 3.19rem;
    }
    .catalog-category-body {
      grid-template-columns: repeat(4,1fr);
    }
    .catalog-card-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .ready-solutions-card-grid {
      grid-template-columns: repeat(3,1fr);
    }
  }

  .result-footer {

  }
}

.result-title {

}

@media only screen and (max-width: 1170px) {
  .result--wrapper {
    .result-head {
    }

    .result-body--block {
      .page-title {
      }
      &:nth-child(2) {
      }
      .catalog-category-body {
      }
      .catalog-card-grid {
      }
      .ready-solutions-card-grid {
      }
    }

    .result-footer {

    }
  }
}

@media only screen and (max-width: 1140px) {
  .result--wrapper {
    @include baseRootLeftRightPaddingsM();
    .result-head {
      text-align: left;
      padding-bottom: 3rem;
    }

    .result-body--block {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 2.37rem;
      .page-title {
        padding-left: unset;
        padding-bottom: 1.75rem;
      }
      &:nth-child(2) {
        padding-top: 3.19rem;
      }
      .catalog-category-body {
        grid-template-columns: repeat(4,1fr);
      }
      .catalog-card-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .ready-solutions-card-grid {
        grid-template-columns: repeat(3,1fr);
      }
    }

    .result-footer {

    }
  }
}

@media only screen and (max-width: 780px) {
  .result--wrapper {
    padding-top: 2.5rem;
    .result-head {
      padding-bottom: 1.37rem;
      p {
        padding-left: unset;
      }
    }
    .result-body {
      .searchbar--form {
        max-width: 100%;
      }
    }
    .result-body--block {
      padding-top: 1.37rem;
      .page-title {
      }
      &:nth-child(2) {
        padding-top: 1.37rem;
      }
      .catalog-category-body {
        grid-template-columns: repeat(3,1fr);
        padding-bottom: 1.87rem;
      }
      .catalog-card-grid {
        grid-template-columns: repeat(3,1fr);
      }
      .ready-solutions-card-grid {
        grid-template-columns: repeat(2,1fr);
      }
    }
    .result-footer {
    }
  }
}
@media only screen and (max-width: 560px) {
  .result--wrapper {
    .result-head {
      p {
      }
    }
    .result-body {
      .searchbar--form {
      }
    }
    .result-body--block {
      .page-title {
      }
      &:nth-child(2) {
      }
      .catalog-category-body {
        grid-template-columns: repeat(2,1fr);
      }
      .catalog-card-grid {
        grid-template-columns: repeat(2,1fr);
      }
      .ready-solutions-card-grid {
        grid-template-columns: repeat(1,1fr);
      }
    }
    .result-footer {
    }
  }
}
@media only screen and (max-width: 480px) {
  .result--wrapper {
    .result-head {
      p {
      }
    }
    .result-body {
      .searchbar--form {
      }
    }
    .result-body--block {
      .page-title {
      }
      &:nth-child(2) {
      }
      .catalog-category-body {
        grid-template-columns: repeat(1,1fr);
      }
      .catalog-card-grid {
        grid-template-columns: repeat(1,1fr);
      }
      .ready-solutions-card-grid {
        grid-template-columns: repeat(1,1fr);
      }
    }
    .result-footer {
    }
  }
}