.template-detail {
  table {
    color: #33363C;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 1.4rem */
    border-collapse: collapse;
    width: 100%;
    border-radius: 0.9375rem;
    background-color: #F7F7FA;
    table-layout: fixed;
    thead {
      border-radius: 0.9375rem;
      tr {
        border-radius: 0.9375rem;
        th {
          background-color: #EFEFF5;
          padding: 1.19rem;
        }
      }
    }
    tbody {
      td, th {
        //border: 1px solid #dddddd;
        text-align: left;
        padding: 1.19rem;
      }

      tr:nth-child(even) {
        background-color: #EFEFF5;
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  .template-detail {
    table {
      display: block;
      overflow-x: scroll;
      position: relative;
      thead {
        position: sticky;
        top: 0;
        tr {
          th {
            width: 12rem;
            font-size: 12px;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td {
            min-width: 12rem;
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 580px) {
  .template-detail {
    table {
      thead {
      }
      tbody {
      }
      th,td {
      }
    }
  }
}