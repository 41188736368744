@import "base/helpers";
@import "global/colors";

footer {
  display: flex;
  flex-direction: column;
  gap: 4.75rem;
  background-color: $blackPrimary;
  margin-top: 3rem;
  padding-top: 3.12rem;
  padding-bottom: 1.44rem;

  .footer-top {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .footer-column {
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-copyright {
      color: rgba(255, 255, 255, 0.20);
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}

.footer-logo {
  img {
    width: 100%;
    height: auto;
  }
}

.footer-menu {
  .footer-menu--wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.37rem;

    .footer-menu--item {
      a {
        color: $whitePrimary;
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }
}

.contact-addresses-menu {
  display: flex;
  flex-direction: column;
  gap: 1.12rem;

  .contact-address-menu-item {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .contact-address-menu-item-city {
      color: $whitePrimary;
    }

    .contact-address-menu-item-phone {
      color: $whitePrimary;
      font-weight: 400;
    }

    .contact-address-menu-item-address {
      color: $whitePrimary;
      font-weight: 400;
    }
  }
}

.contact-address-block {
  display: flex;
  flex-direction: column;
  gap: 1.13rem;
}

.phone-base {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .phone-base--title {
    color: $whitePrimary;
  }

  .phone-base--subtitle {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.22);
  }

  .phone-base--link {
    color: $whitePrimary;
    font-weight: 400;
  }
}

.email-base {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .email-base--title {
    color: $whitePrimary;
  }

  .email-base--link {
    color: $whitePrimary;
    font-weight: 400;
  }
}

.soc-menu {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .soc-menu--title {
    color: $whitePrimary;
  }

  .soc-menu--items {
    display: flex;
    align-items: center;
    gap: 1.13rem;

    .soc-menu--item {
      color: $whitePrimary;
    }
  }
}

.footer-form {
  max-width: 30rem;
  //width: fit-content;
  .footer-form-title {
    padding-bottom: 1.31rem;
    color: $whitePrimary;
  }

  .footer-form--wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.19rem;

    .footer-form-email--label {
      flex: 7;

      .footer-form-email {
        color: $whitePrimary;
        &::placeholder {
          color: $whitePrimary;
        }
      }
    }

    .footer-form-send {
      flex: 3;
      color: $whitePrimary;
    }
  }

  .footer-form-privacy-policy {
    color: rgba(255, 255, 255, 0.60);
    text-align: left;
  }
}

.footer-bottom-menu {
  .footer-bottom-menu--wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.75rem;

    .footer-bottom-menu--item {
      .footer-bottom-menu--item-link {
        color: rgba(255, 255, 255, 0.20);
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
}


@media only screen and (max-width: 1090px) {
  footer {
    gap: 2.25rem;
    padding-top: 1.56rem;
    padding-bottom: 1.88rem;

    .footer-top {
      flex-direction: column;
      gap: 2.25rem;

      .footer-column {
        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 3;
        }

        &:nth-child(3) {
          order: 5;
        }

        &:nth-child(4) {
          order: 4;
        }

        &:nth-child(5) {
          order: 2;
        }
      }
    }

    .footer-bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: .75rem;

      .footer-copyright {
        font-size: 0.875rem;
      }
    }
  }
  .footer-logo {
    img {
      max-width: 6.96669rem;
      width: 100%;
      height: auto;
    }
  }

  .footer-menu {
    .footer-menu--wrapper {
      gap: 1rem;

      .footer-menu--item {
        a {
          font-size: 1rem;
        }
      }
    }
  }

  .contact-addresses-menu {
    display: flex;
    flex-direction: column;
    gap: 1.12rem;

    .contact-address-menu-item {
      display: flex;
      flex-direction: column;
      gap: .5rem;

      .contact-address-menu-item-city {
        color: $whitePrimary;
      }

      .contact-address-menu-item-phone {
        color: $whitePrimary;
        font-weight: 400;
      }

      .contact-address-menu-item-address {
        color: $whitePrimary;
        font-weight: 400;
      }
    }
  }

  .contact-address-block {
    display: flex;
    flex-direction: column;
    gap: 1.13rem;
  }

  .phone-base {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .phone-base--title {
      color: $whitePrimary;
    }

    .phone-base--subtitle {
      font-weight: 400;
      color: rgba(255, 255, 255, 0.22);
    }

    .phone-base--link {
      color: $whitePrimary;
      font-weight: 400;
    }
  }

  .email-base {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .email-base--title {
      color: $whitePrimary;
    }

    .email-base--link {
      color: $whitePrimary;
      font-weight: 400;
    }
  }

  .soc-menu {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .soc-menu--title {
      color: $whitePrimary;
    }

    .soc-menu--items {
      display: flex;
      align-items: center;
      gap: 1.13rem;

      .soc-menu--item {
        color: $whitePrimary;
      }
    }
  }

  .footer-form {
    max-width: 30rem;
    //width: fit-content;
    .footer-form-title {
      padding-bottom: 1.31rem;
      color: $whitePrimary;
    }

    .footer-form--wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      padding-bottom: 1.19rem;

      .footer-form-email--label {
        flex: 8;

        .footer-form-email {
          &::placeholder {
            color: $whitePrimary;
          }
        }
      }

      .footer-form-send {
        flex: 2;
        color: $whitePrimary;
        max-width: 7.5rem;
      }
    }

    .footer-form-privacy-policy {
      color: rgba(255, 255, 255, 0.60);
      text-align: left;
    }
  }

  .footer-bottom-menu {
    .footer-bottom-menu--wrapper {
      flex-direction: column;
      align-items: flex-start;
      gap: .75rem;

      .footer-bottom-menu--item {
        .footer-bottom-menu--item-link {
          font-size: 0.75rem;
        }
      }
    }
  }
}