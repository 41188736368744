@import "../../../scss/global/colors";
.accordion {
  padding-top: 3.25rem;
  .accordion--content--wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 0.9375rem;
    overflow: hidden;
    .accordion--item {
      &:nth-child(even) {
        background: #EFEFF5;
      }
      &:nth-child(odd) {
        background: $darkWhitePrimary;
      }
      .accordion--title {
        cursor: pointer;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;
        transition: 0.4s;
        //border-radius: 1rem;
        padding: 1.69rem 2.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 160%; /* 1.8rem */
        &.accordion--active{
          &::after {
            transform: rotate(-90deg);
          }
        }
        &::after {
          content: url("/img/icons/arrow-right-black.svg");
          transform: rotate(90deg);
          color: #777;
          font-weight: bold;
          float: right;
          margin-left: 5px;
          transition: all 0.4s ease-in-out;
        }
      }
      /*.accordion--active {
        border-radius: 1rem 1rem 0 0;
      }*/
      .accordion--active, .accordion--title:hover {
        //background: $cSecondaryLightBlue;
      }
    }
    .accordion--content {
      padding: 1.69rem 2.25rem;
      padding-top: 0;
      padding-bottom: 2.44rem;
      display: none;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      border-radius: 0 0 1rem 1rem;
      max-width: 61rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 160%; /* 1.6rem */
    }
  }
}

@media only screen and (max-width: 680px) {
  .accordion {
    padding-top: 1.37rem;
    .accordion--content--wrapper {
      .accordion--item {
        .accordion--title {
          font-size: 0.875rem;
          padding: 1.13rem 1rem;
          &.accordion--active{
            &::after {
            }
          }
          &::after {
            width: 1.125rem;
            height: 1.125rem;
            object-fit: fill;
          }
        }
        /*.accordion--active {
          border-radius: 1rem 1rem 0 0;
        }*/
        .accordion--active, .accordion--title:hover {
          //background: $cSecondaryLightBlue;
        }
      }
      .accordion--content {
        padding: 0 1.13rem 1rem;
        font-size: .75rem;
      }
    }
  }
}