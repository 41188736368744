@import "../base/helpers";
@import "../global/colors";

header {
  background: #F7F7FA;
  position: relative;
  .header-wrapper {
    padding-top: 0.94rem;
    padding-bottom: 0.94rem;
    display: flex;
    gap: 2.63rem;
    align-items: center;
    justify-content: space-around;
  }
}
.primary-menu-content {
  display: flex;
  gap: 2.62rem;
  align-items: center;
}
.header-menu {
  .header-menu--wrapper {
    display: flex;
    gap: 2.63rem;
    align-items: center;
    .current-menu-item {
      .header-menu-item--link {
        color: $redPrimary
      }
    }
    .header-menu-item--link {
      &.active, &:hover {
        color: $redPrimary
      }
    }
    .header-menu-item {
      &:last-child {
        .header-menu-item--link {
          font-weight: 600;
          position: relative;
          &:after {
            display: block;
            content: url("/img/icons/arrow-black.svg");
            width: 0.75rem;
            height: 0.75rem;
            position: absolute;
            right: -0.8rem;
            top: -50%;
            transform: translateY(50%);
          }
        }
      }
    }
  }
}

.header-menu-secondary {
  .header-menu-secondary--wrapper {
    display: flex;
    gap: .5rem;
    align-items: center;
    .header-menu-secondary--item {
      padding: 0.75rem;
      .header-menu-secondary--item-link {
        font-size: 1.125rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
      }
      .header-menu-button.active {
        color: $redPrimary;
      }
      &:nth-child(1){
        padding: unset;
      }
    }
    .header-menu-contact--item--column {
      display: flex;
      flex-direction: column;
      text-align: right;
      align-items: flex-end;
    }
  }
}

@media only screen and (max-width: 1730px){
  header {
    .header-wrapper {
      gap: 1.63rem;
    }
  }
  .header-menu {
    .header-menu--wrapper {
      gap: 1.63rem;
    }
  }
  .primary-menu-content {
    gap: 1.63rem;
  }
}

@media only screen and (max-width: 1620px){
  .header-menu-secondary {
    .header-menu-secondary--wrapper {
      gap: 1.63rem;
      .header-menu-secondary--item {
        padding: .5rem;
        .header-menu-contact--item-link {
          white-space: nowrap;
        }
        .header-menu-secondary--item-link {
          span {
          }
          i {
            span {
            }
          }
        }
        &:nth-child(1){
        }
      }
      .header-menu-contact--item--column {
      }
    }
  }
}

@media only screen and (max-width: 1440px){
  .header-menu {
    .header-menu--wrapper {
      gap: .8rem;
      .header-menu-item {
        .header-menu-item--link {
          white-space: nowrap;
          font-size: .8rem;
        }
      }
    }
  }
  .header-menu-secondary {
    .header-menu-secondary--wrapper {
      gap: 1rem;
      .header-menu-secondary--item {
        .header-menu-contact--item-link {
          font-size: .8rem;
          span {
          }
        }
        .header-menu-secondary--item-link {
          font-size: .8rem;
          span {
            //display: none;
          }
          i {
            span {
              //display: inherit;
            }
          }
        }
        &:nth-child(1){
        }
      }
      .header-menu-contact--item--column {
      }
    }
  }
}

@media only screen and (max-width: 1370px){
  header {
    .header-wrapper {
      .primary-menu-content {
        .catalog-button {

        }
      }
    }
  }
  .header-menu {
    .header-menu--wrapper {
      .header-menu-item {
        .header-menu-item--link {
        }
      }
    }
  }
  .header-menu-secondary {
    .header-menu-secondary--wrapper {
      .header-menu-secondary--item {
        padding: unset;
        .header-menu-contact--item-link {
        }
        .header-menu-secondary--item-link {
          span {
          }
          i {
            span {
            }
          }
        }
        &:nth-child(1){
        }
      }
      .header-menu-contact--item--column {
      }
    }
  }
}

@media only screen and (max-width: 1230px){
  header {
    .header-wrapper {
      .primary-menu-content {
        .catalog-button {
          display: none;
        }
      }
    }
  }
  .header-menu {
    .header-menu--wrapper {
      .header-menu-item {
        .header-menu-item--link {
        }
      }
    }
  }
  .header-menu-secondary {
    .header-menu-secondary--wrapper {
      .header-menu-secondary--item {
        .header-menu-contact--item-link {
        }
        .header-menu-secondary--item-link {
          span {
          }
          i {
            span {
            }
          }
        }
        &:nth-child(1){
        }
      }
      .header-menu-contact--item--column {
      }
    }
  }
}

@media only screen and (max-width: 1170px){
  header {
    .header-wrapper {
      .primary-menu-content {
        .catalog-button {
        }
      }
    }
  }
  .header-menu {
    .header-menu--wrapper {
      .header-menu-item {
        .header-menu-item--link {
        }
      }
    }
  }
  .header-menu-secondary {
    .header-menu-secondary--wrapper {
      .header-menu-contact--item--column {
        display: none;
      }
      .header-menu-secondary--item {
        .header-menu-contact--item-link {
        }
        .header-menu-secondary--item-link {
          span {
          }
          i {
            span {
            }
          }
        }
        &:nth-child(1){
        }
      }
      .header-menu-contact--item--column {
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  header {
    .header-wrapper {
      padding: 0.75rem 1.25rem 0.91rem;
      justify-content: space-between;
    }
  }
  .logo-header {
    img {
      width: 100%;
      height: auto;
    }
  }
  .primary-menu-content {
    display: none;
  }
  .header-menu {
    display: none;
    .header-menu--wrapper {
    }
  }

  .header-menu-secondary {
    .header-menu-secondary--wrapper {
      gap: 1.25rem;
      .header-menu-secondary--item {
        padding: unset;
        .header-menu-secondary--item-link {
          span {
            display: none;
          }
          i {
            span {
              display: inherit;
            }
          }
        }
        &:nth-child(1){
        }
      }
      .header-menu-contact--item--column {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  header {
    .header-wrapper {
    }
  }
  .logo-header {
    img {
      max-width: 6rem;
    }
  }
  .primary-menu-content {
  }
  .header-menu {
    .header-menu--wrapper {
    }
  }

  .header-menu-secondary {
    .header-menu-secondary--wrapper {
      .header-menu-secondary--item {
        .header-menu-secondary--item-link {
          span {
          }
          i {
            span {
            }
          }
        }
        &:nth-child(1){
        }
      }
      .header-menu-contact--item--column {
      }
    }
  }
}