.template-detail-header {
  padding: 11.44rem 3.62rem 3.62rem;
  border-radius: 1.25rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  aspect-ratio: 6 / 2;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.78) 0%, rgba(0, 0, 0, 0.00) 100%);
  }

  .template-detail-header--title {
    max-width: 50rem;
    position: relative;
    z-index: 1;
  }
}

@media only screen and (max-width: 1470px) {
  .template-detail-header {
    padding: 6.44rem 3.62rem 3.62rem;
  }
}
@media only screen and (max-width: 980px) {
  .template-detail-header {
    //min-height: 9.375rem;
    padding: 3.37rem 1.13rem 1.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    &:before {
    }

    .template-detail-header--title {
    }
  }
}