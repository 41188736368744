@import "./global/colors";
@import "./base/helpers";

.certificates {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  padding-left: 4.38rem;

  .certificates--wrapper {
    display: flex;
  }
}

.certificate-card {
  width: 17rem;
  height: 23.625rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
}

.certificate-panel {
  background: $redPrimary;
  margin-right: 1.88rem;
  padding: 2.88rem 3.19rem 2rem 1.69rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .certificate-panel--title {
    color: $whitePrimary;
    font-size: 1.625rem;
    font-weight: 700;
  }

  .certificate-panel-control {
    display: flex;
    gap: 1rem;

    span {
      cursor: pointer;
    }
  }
}

.certificate-card--items {
  .certificate-card {
    position: relative;
    width: 17rem;
    height: 23.625rem;
    flex-shrink: 0;
    border-radius: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .certificate-card--img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .button-certificate-show {
      display: none;
    }

    &.active {
      &::before {
        background: rgba(51, 54, 60, 0.52);
        backdrop-filter: blur(10px);
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
      }
      .button-certificate-show {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: 2.94rem;
        margin-right: 2.94rem;
        padding: 0.75rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon--gallery-white {
          margin-left: 1rem;
        }

        &:hover {
          .icon--gallery-white {
            margin-left: 1rem;
            background-image: url("/img/icons/gallery-red.svg");
          }
        }
      }
    }

  }
}

/* The Modal (background) */
.certificates-modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,.7);
}

/* Modal Content */
.certificates-modal--content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 512px;
}

/* The Close Button */
.certificates-modal--close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.certificates-modal--close:hover,
.certificates-modal--close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

/* Hide the slides by default */
.certificates-modal--slides {
  display: none;
}
.active {
  opacity: 1;
}

@media only screen and (max-width: 980px){
  .certificates {
    padding-bottom: 3rem;
    padding-top: unset;
    @include baseRootLeftRightPaddingsM();
    .certificates--wrapper {
      flex-direction: column;
      gap: 1.44rem;
    }
  }

  .certificate-card {
  }

  .certificate-panel {
    height: unset;
    width: 100%;
    gap: 2.5rem;
    .certificate-panel--title {
      font-size: 1rem;
    }

    .certificate-panel-control {

      span {
      }
    }
  }

  .certificate-card--items {
    .certificate-card {
      width: 49%;
      height: auto;
      .certificate-card--img {
      }
      .button-certificate-show {
      }

      &.active {
        &::before {
        }
        .button-certificate-show {
          margin-left: 1rem;
          margin-right: 1rem;
          .icon--gallery-white {
            margin-left: .5rem;
          }
        }
      }

    }
  }
}

@media only screen and (max-width: 380px){
  .certificates {
    @include baseRootLeftRightPaddingsM();
    .certificates--wrapper {
    }
  }

  .certificate-card {
  }

  .certificate-panel {
    gap: 2.5rem;
    .certificate-panel--title {
    }

    .certificate-panel-control {

      span {
      }
    }
  }

  .certificate-card--items {
    .certificate-card {
      .certificate-card--img {
      }
      .button-certificate-show {
      }

      &.active {
        &::before {
        }
        .button-certificate-show {
          .icon--gallery-white {
            display: none;
          }
        }
      }

    }
  }
}