@import "./global/colors";

.home-contact-us {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $darkWhitePrimary;
  padding-top: 6.63rem;
  padding-bottom: 5.25rem;
  gap: 11.31rem;

  .home-contact-us--left {
    max-width: 35.75rem;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;

    .home-contact-us--title {
      font-size: 1.625rem;
      font-weight: 700;
    }

    .home-contact-us--body {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      p {
        font-size: 1.25rem;
        line-height: 140%; /* 1.75rem */
      }
    }
  }

  .home-contact-us--right {
    max-width: 26.375rem;
  }
}

.home-contact-us--form {
  display: flex;
  flex-direction: column;
  gap: 1.13rem;
  .home-contact-us--form-row {
    display: flex;
    gap: 1.13rem;
    justify-content: center;
    label {
      input {
      }
      textarea {
        height: 7.8125rem;
      }
    }
    .home-contact-us--name--label {
      flex: 5;
    }
    .home-contact-us--phone--label {
      flex: 5;
    }
    .home-contact-us--message--label {
      flex: 10;
    }
    .home-contact-us--send {

    }
  }
  .home-contact-us--privacy-policy {
    a {
    }
  }
}

@media only screen and (max-width: 1330px){
  .home-contact-us {
    gap: 5.31rem;
    .home-contact-us--left {
      max-width: 27.75rem;
    }
  }
}

@media only screen and (max-width: 1150px){
  .home-contact-us {
    gap: 3.31rem;
  }
}

@media only screen and (max-width: 1150px){
  .home-contact-us {
    gap: 3.31rem;
  }
}

@media only screen and (max-width: 980px){
  .home-contact-us {
    flex-direction: column;
    gap: 1.9375rem;
    padding: 1.75rem 1.25rem;

    .home-contact-us--left {
      gap: 1rem;
      .home-contact-us--title {
        font-size: 1.25rem;
      }
      .home-contact-us--body {
        gap: 1rem;
        p {
          font-size: .75rem;
        }
      }
    }
    .home-contact-us--right {
    }
  }
  .home-contact-us--form {
    .home-contact-us--form-row {
      flex-direction: column;
      label {
        input {
        }
        textarea {
        }
      }
      .home-contact-us--name--label {
      }
      .home-contact-us--phone--label {
      }
      .home-contact-us--message--label {
      }
      .home-contact-us--send {
      }
    }
    .home-contact-us--privacy-policy {
      font-size: 0.625rem;
      a {
        font-size: 0.625rem;
      }
    }
  }
}