@import "../../../scss/global/colors";

.product-card {
  border-radius: 1.25rem;
  background: $darkWhitePrimary;
  padding: 1.31rem;
  display: flex;
  flex-direction: column;
  //justify-content: space-around;
  justify-content: space-between;
  gap: 2rem;
  //height: 20.875rem;

  .product-card--header {
    .product-card--img {
      width: 100%;
      height: auto;
      max-height: 150px;
    }
  }

  .product-card--footer {
    display: flex;
    flex-direction: column;
    gap: 1.94rem;

    .product-card--title {

    }

    .product-card--link {
      width: fit-content;
    }
  }
}

@media only screen and (max-width: 980px) {
  .product-card {
    height: auto;
  }
}

@media only screen and (max-width: 480px) {
  .product-card {
    height: auto;
    flex-direction: row;
    align-items: center;
    border-radius: 0.625rem;
    padding: 1.13rem 1.31rem;
    justify-content: flex-start;
    position: relative;
    gap: .75rem;

    &:before {
      content: '';
      background-image: url('/img/icons/arrow-right--light-gray.svg');
      display: block;
      position: absolute;
      width: 1rem;
      z-index: 2;
      height: 1rem;
      right: 1rem;
      object-fit: cover;
      object-position: center;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .product-card--header {
    max-width: 4.75rem;
  }
  .product-card--footer {
    margin-right: 1.5rem;
  }
  .product-card--title {

  }
  .product-card--link {
    display: none;
  }
}