@import "../../../scss/global/colors";
.contact-us-block {
  padding-bottom: 5.81rem;
  .contact-us-block--wrapper {
    padding: 3.87rem 18.87rem 3.87rem 9.44rem;
    border-radius: 1.25rem;
    background: $redPrimary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .contact-us-block--left {
      max-width: 32rem;
      .contact-us-block--title {
        font-size: 1.625rem;
        font-weight: 700;
        color: $whitePrimary;
      }

      .contact-us-block--subtitle {
        color: $whitePrimary;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 140%; /* 1.75rem */
      }
    }
    .contact-us-block--right {

    }
  }
}

@media only screen and (max-width: 1342px){
  .contact-us-block {
    .contact-us-block--wrapper {
      padding: 3.87rem 9.44rem 3.87rem 9.44rem;
      .contact-us-block--left {
        .contact-us-block--title {
        }

        .contact-us-block--subtitle {
        }
      }
      .contact-us-block--right {

      }
    }
  }
}

@media only screen and (max-width: 1160px){
  .contact-us-block {
    .contact-us-block--wrapper {
      padding: 3.87rem 5.44rem 3.87rem 5.44rem;
      .contact-us-block--left {
        .contact-us-block--title {
          font-size: 1rem;
        }

        .contact-us-block--subtitle {
          font-size: 0.875rem;
        }
      }
      .contact-us-block--right {
        button {
          white-space: nowrap;
          font-size: .875rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px){
  .contact-us-block {
    .contact-us-block--wrapper {
      padding: 1.5rem 3rem 2.87rem 1.62rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.13rem;
      .contact-us-block--left {
        display: flex;
        flex-direction: column;
        gap: 0.88rem;
        .contact-us-block--title {
        }

        .contact-us-block--subtitle {
        }
      }
      .contact-us-block--right {
        button {
        }
      }
    }
  }
}
