.news--wrapper {
  padding-bottom: 8.12rem;
  .news-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 3rem;
    column-gap: 1.875rem;
  }
  .news-footer {

  }
}

@media only screen and (max-width: 980px) {
  .news--wrapper {
    .news-body {
      grid-template-columns: repeat(3, 1fr);
    }
    .news-footer {
    }
  }
}

@media only screen and (max-width: 680px) {
  .news--wrapper {
    .news-body {
      grid-template-columns: repeat(2, 1fr);
    }
    .news-footer {
    }
  }
}

@media only screen and (max-width: 480px) {
  .news--wrapper {
    .news-body {
      grid-template-columns: repeat(1, 1fr);
      gap: 1.04rem;
    }
    .news-footer {
    }
  }
}