.catalog-category-body {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.875rem;
  grid-template-rows: subgrid;
  //padding-bottom: 7.5rem;
}

@media only screen and (max-width: 1500px){
  .catalog-category-body {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1440px){
  .catalog-category-body {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 680px){
  .catalog-category-body {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 480px){
  .catalog-category-body {
    grid-template-columns: repeat(1, 1fr);
  }
}