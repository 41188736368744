@import "./global/colors";

.our-clients-partners {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .our-clients {
    background-color: $redPrimary;
    padding: 3.44rem 7.66rem 7.69rem 4.38rem;
    display: flex;
    flex-direction: column;
    gap: 5.56rem;
    width: 100%;

    .our-clients--title {
      color: $whitePrimary;
      font-size: 1.625rem;
      font-weight: 700;
    }
    .our-clients-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2.81rem;
      grid-row-gap: 2.81rem;
      .our-client-card {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        border-radius: 0.625rem;
        background: #F7F7FA;
        padding: 0.88rem;
        .our-client-card--img {
          width: 100%;
          height: auto;
          aspect-ratio: 4 / 2;
        }
      }
    }
  }

  .our-partners {
    background-color: $blackPrimary;
    padding: 3.44rem 7.66rem 7.69rem 4.38rem;
    display: flex;
    flex-direction: column;
    gap: 5.56rem;
    width: 100%;

    .our-partners--title {
      color: $whitePrimary;
      font-size: 1.625rem;
      font-weight: 700;
    }
    .our-partners-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2.81rem;
      grid-row-gap: 2.81rem;
      .our-partner-card {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        border-radius: 0.625rem;
        background: #F7F7FA;
        padding: 0.88rem;
        .our-partner-card--img {
          width: 100%;
          height: auto;
          aspect-ratio: 4 / 2;
        }
      }
      .our-partner-card--big {
        flex-direction: column;
        gap: 1.37rem;
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
        .our-partner-card--img {
          width: unset;
          height: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: 1880px){
  .our-clients-partners {
    .our-clients {
      .our-clients--title {
      }
      .our-clients-cards {
        //grid-template-columns: repeat(3, 1fr);
        .our-client-card {
          //width: 100%;
          img {
            //width: 100%;
          }
        }
      }
    }

    .our-partners {
      //padding-right: 4rem;
      .our-partners--title {
      }
      .our-partners-cards {
        .our-partner-card {
          //width: 100%;
          &.our-partner-card--big {
            width: unset;
            img {
              width: unset;
            }
          }
          img {
            //width: 100%;
          }
        }
        .our-partner-card--big {
        }
      }
    }
  }
}

@media only screen and (max-width: 1450px){
  .our-clients-partners {
    .our-clients {
      padding-right: 4rem;
      .our-clients--title {
      }
      .our-clients-cards {
        .our-client-card {
          img {
          }
        }
      }
    }

    .our-partners {
      padding-right: 4rem;
      .our-partners--title {
      }
      .our-partners-cards {
        .our-partner-card {

        }
        .our-partner-card--big {
        }
      }
    }
  }
}

@media only screen and (max-width: 1390px){
  .our-clients-partners {
    .our-clients {
      padding: 1.5rem 1.25rem 2.57rem;
      .our-clients--title {
      }
      .our-clients-cards {
        .our-client-card {
          img {
          }
        }
      }
    }

    .our-partners {
      padding: 1.5rem 1.25rem 2.57rem;
      .our-partners--title {
      }
      .our-partners-cards {
        .our-partner-card {

        }
        .our-partner-card--big {
        }
      }
    }
  }
}

@media only screen and (max-width: 980px){
  .our-clients-partners {
    grid-template-columns: 1fr;
    .our-clients {
      gap: 2.25rem;
      .our-clients--title {
      }
      .our-clients-cards {
        gap: 1.72rem;
        .our-client-card {
          img {
          }
        }
      }
    }

    .our-partners {
      gap: 2.25rem;
      .our-partners--title {
      }
      .our-partners-cards {
        gap: 1.72rem;
        .our-partner-card {

        }
        .our-partner-card--big {
        }
      }
    }
  }
}
@media only screen and (max-width: 580px){
  .our-clients-partners {
    .our-clients {
      .our-clients--title {
        font-size: 1rem;
      }
      .our-clients-cards {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.72rem;
        //grid-template-columns: repeat(2,1fr);
        .our-client-card {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .our-partners {
      .our-partners--title {
        font-size: 1rem;
      }
      .our-partners-cards {
        display: flex;
        flex-wrap: wrap;
        .our-partner-card {
          flex-shrink: 0;
          width: 47%;
          //max-width: 7.89813rem;
          &:nth-child(1) { grid-area: unset; order: 2;}
          &:nth-child(2) { grid-area: unset; order: 3;}
          &:nth-child(3) { grid-area: unset;
            flex-shrink: 10;
            width: 100%;
            max-width: 100%;
            order: 1;
          }
          &:nth-child(4) { grid-area: unset; order: 4;}
          &:nth-child(5) { grid-area: unset; order: 5;}
          &:nth-child(6) { grid-area: unset; order: 6;}
          &:nth-child(7) { grid-area: unset; order: 7;}
          &:nth-child(8) { grid-area: unset; order: 8;}
          &:nth-child(9) { grid-area: unset; order: 9;}
          &:nth-child(10) { grid-area: unset; order: 10;}
          &:nth-child(11) { grid-area: unset; order: 12;}
          &:nth-child(12) { grid-area: unset; order: 12;}
          img {
            width: 100%;
            height: auto;
          }
        }
        .our-partner-card--big {
        }
      }
    }
  }
}

@media only screen and (max-width: 480px){
  .our-clients-partners {
    .our-clients {
      .our-clients--title {
      }
      .our-clients-cards {
        //display: flex;
        //flex-wrap: wrap;
        //grid-column-gap: unset;
        //grid-row-gap: unset;
        gap: 1.71rem;
        //grid-template-columns: repeat(2,1fr);
        .our-client-card {
          //max-width: fit-content;
          width: 100%;
          //max-width: 7.89813rem;
          img {
          }
        }
      }
    }

    .our-partners {
      .our-partners--title {
      }
      .our-partners-cards {
        .our-partner-card {
          //max-width: fit-content;
          //width: 45%;
          width: 45%;
          img {
          }
        }
        .our-partner-card--big {
        }
      }
    }
  }
}