.card--bgc {
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10rem 1.31rem 2.19rem;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.78) 0%, rgba(0, 0, 0, 0.00) 100%);
  }
  p {
    color: $whitePrimary;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 14.5rem;
    z-index: 1;
  }
}

@media only screen and (max-width: 580px) {
  .card--bgc {
    border-radius: 1.25rem;
    padding: 6.75rem 1.12rem 1.5rem;
    p {
      font-size: .875rem;
      max-width: 14.5rem;
    }
  }
}