@import "./global/colors";

.home-seo-text {
  padding-bottom: 8.88rem;
  padding-top: 3rem;
  .home-seo-text--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .home-seo-text--content--wrapper {
      position: relative;

      .home-seo-text--content {
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        line-clamp: 4;
        overflow: hidden;
        transition: all 0s linear 0.33s, opacity 0.33s linear;
      }

      .home-seo-text--content--shadow {
        background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, $whitePrimary 100%);
        background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%, $whitePrimary 100%);
        background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, $whitePrimary 100%);
        background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, $whitePrimary 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: visibility 0s linear 0.33s, opacity 0.33s linear;
      }
    }

    .home-seo-text--footer {
      //margin-top: -0.2rem;
      z-index: 1;

      .home-seo-text--button {
        cursor: pointer;
        .icon--arrow-bottom--red {
          transition: rotate 0s linear 0.33s, opacity 0.33s linear;
          //rotate: 90deg;
        }
      }
    }
  }
}

.home-seo-text--open {
  .home-seo-text--wrapper {
    .home-seo-text--content--wrapper {
      .home-seo-text--content {
        -webkit-line-clamp: unset;
        line-clamp: unset;
        transition-delay: 0s;
      }

      .home-seo-text--content--shadow {
        //display: none;
        transition-delay: 0s;
        visibility: hidden;
        opacity: 0;
      }
    }

    .home-seo-text--footer {
      .home-seo-text--button {
        .icon--arrow-bottom--red {
          rotate: 180deg;
          transition-delay: 0s;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 980px) {
  .home-seo-text {
    padding-bottom: 3.25rem;
    .home-seo-text--wrapper {
      .home-seo-text--content--wrapper {
        .home-seo-text--content {
          -webkit-line-clamp: 8;
          line-clamp: 8;
        }

        .home-seo-text--content--shadow {
        }
      }

      .home-seo-text--footer {
        .home-seo-text--button {
          .icon--arrow-bottom--red {
          }
        }
      }
    }
  }
}