.template-detail-list {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem;
  .template-detail-list--title {
    padding-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 160%; /* 2rem */
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    li {
      //list-style-type: disc;
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      &:before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: red; /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: block; /* Needed to add space between the bullet and the text */
        width: 0.75rem; /* Also needed for space (tweak if needed) */
        height: 0.75rem; /* Also needed for space (tweak if needed) */
        //margin-left: -1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  .template-detail-list {
    padding-top: 1.38rem;
    padding-bottom: 1.38rem;
    .template-detail-list--title {
      padding-bottom: 1rem;
      font-size: 1rem;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      li {
        //list-style-type: disc;
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        &:before {
          content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: red; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: block; /* Needed to add space between the bullet and the text */
          width: 0.75rem; /* Also needed for space (tweak if needed) */
          height: 0.75rem; /* Also needed for space (tweak if needed) */
          //margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
      }
    }
  }
}