.product-detail {
  display: flex;
  gap: 1.8rem;
  padding-top: 3rem;
}
@media only screen and (max-width: 1240px) {
  .product-detail {
    gap: 1.37rem;
    padding-top: 1.5rem;
    flex-direction: column;
  }
}