.template-detail-p-slider {
  padding-top: 2.625rem;
  .template-detail-p-slider-wrapper {
    position: relative;
    .template-detail-p-slider-panel-control {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      .template-detail-p-slider-panel-control--left, .template-detail-p-slider-panel-control--right {
        position: absolute;
        top: 50%;
        z-index: 2;
      }
      .template-detail-p-slider-panel-control--left {
        left: 0;
        transform: translateX(-50%);
      }
      .template-detail-p-slider-panel-control--right {
        right: 0;
        transform: translateX(50%);
      }
    }
    .template-detail-p-slider--items {
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 980px) {
}

@media only screen and (max-width: 490px) {
  .template-detail-p-slider {
    padding-top: 22px;
    .template-detail-p-slider-wrapper {
      .template-detail-p-slider-panel-control {
        position: unset;
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-end;
        gap: 14px;
        .template-detail-p-slider-panel-control--left, .template-detail-p-slider-panel-control--right {
          position: unset;
          padding-bottom: 18px;
        }
        .template-detail-p-slider-panel-control--left {
          left: 0;
          transform: unset;
        }
        .template-detail-p-slider-panel-control--right {
          right: 0;
          transform: unset;
        }
      }
    }
  }
}