@import "colors";
.icon {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
}
.icon--catalog-burger-red {
  width: 1.5rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  //display: flex;
  //gap: 4px;
  //flex-direction: column;
  /*span {
    height: 2px;
    background-color: $redPrimary;
    border-radius: 1px;
    width: 100%;
  }*/
  span {
    display: block;
    position: absolute;
    height: 2px;
    background-color: $redPrimary;
    border-radius: 1px;
    width: 100%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: -8px;
    }

    &:nth-child(2), &:nth-child(3) {
      top: -2px;
    }

    &:nth-child(4) {
      top: 4px;
    }
  }
}
/*.icon--catalog-burger-red {
  width: 1.5rem;
  display: flex;
  gap: 4px;
  flex-direction: column;
  span {
    height: 2px;
    background-color: $redPrimary;
    border-radius: 1px;
    width: 100%;
  }
}*/

.active {
  .icon--catalog-burger-red {
    span {
      background-color: $whitePrimary;
      &:nth-child(1) {
        //top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        //top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}

.icon--burger-short-black {
  width: 1.625rem;
  //height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2px;
    background-color: $blackPrimary;
    border-radius: 1px;
    width: 100%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      width: 83%;
    }

    &:nth-child(1) {
      top: -7px;
    }

    &:nth-child(2), &:nth-child(3) {
      top: 0px;
    }

    &:nth-child(4) {
      top: 7px;
    }
  }
}
.active {
  .icon--burger-short-black {
    span {
      background-color: $redPrimary;
      &:nth-child(1) {
        //top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        //top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
/*.icon--burger-short-black {
  width: 1.625rem;
  display: flex;
  gap: 5px;
  flex-direction: column;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  span {
    height: 2px;
    background-color: $blackPrimary;
    border-radius: 1px;
    width: 100%;

    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      width: 83%;
    }

    &:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 18px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 36px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
}
.active {
  position: relative;
  .icon--burger-short-black {
    //position: relative;
    span {
      position: absolute;
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 100%;
        top: -9px;
        //left: 8px;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 9px;
        //left: 8px;
      }
    }
  }
}*/
.icon--search-black {
  background-image: url("/img/icons/search-black.svg");
  width: 1.5rem;
  height: 1.5rem;
}
.icon--user-black {
  background-image: url("/img/icons/user-black.svg");
  width: 1.5rem;
  height: 1.5rem;
}
.icon--cpu-red {
  background-image: url("/img/icons/cpu-red.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon--people-red {
  background-image: url("/img/icons/people-red.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon--like-red {
  background-image: url("/img/icons/like-red.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon--routing-red {
  background-image: url("/img/icons/route-red.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon--power-red {
  background-image: url("/img/icons/power-red.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon--arrow-right-white {
  background-image: url("/img/icons/arrow-right-white.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon--search-light-grey {
  background-image: url("/img/icons/search-light-grey.svg");
  width: 1.5rem;
  height: 1.5rem;
}
.icon--close-light-grey {
  background-image: url("/img/icons/close-light-grey.svg");
  width: 1.5rem;
  height: 1.5rem;
}
.icon--enter-light-grey {
  background-image: url("/img/icons/enter-light-grey.svg");
  width: 4.3125rem;
  height: 1.5625rem;
}
.icon--arrow-right-black {
  background-image: url("/img/icons/arrow-right-black.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon--arrow-right--red {
  background-image: url("/img/icons/arrow-red-right.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon--arrow-left--red {
  background-image: url("/img/icons/arrow-red-right.svg");
  transform: rotate(180deg);
  width: 2.25rem;
  height: 2.25rem;
}
.icon-arrow-white-red-circle-left {
  background-image: url("/img/icons/arrow-white-red-circle-left.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon-arrow-white-red-circle-right {
  background-image: url("/img/icons/arrow-white-red-circle-right.svg");
  width: 2.25rem;
  height: 2.25rem;
}
.icon-arrow-red-white-circle-left {
  background-image: url("/img/icons/arrow-red-circle-left.svg");
  width: 1.625rem;
  height: 1.625rem;
}
.icon-arrow-red-white-circle-right {
  background-image: url("/img/icons/arrow-red-circle-right.svg");
  width: 1.625rem;
  height: 1.625rem;
}
.icon--gallery-white {
  background-image: url("/img/icons/gallery-white.svg");
  width: 1.5rem;
  height: 1.5rem;
}
.icon--gallery-white {
  background-image: url("/img/icons/gallery-white.svg");
  width: 1.5rem;
  height: 1.5rem;
}

.icon--read-white {
  background-image: url("/img/icons/read-white.svg");
  width: 1.5rem;
  height: 1.5rem;
}

.icon-vk--red {
background-image: url("/img/icons/vk-red.svg");
  width: 2rem;
  height: 2rem;
}

.icon-tme--red {
  background-image: url("/img/icons/tme-red.svg");
  width: 2rem;
  height: 2rem;
}

.icon--arrow-bottom--red {
  background-image: url("/img/icons/arrow-red-down.svg");
  width: 1.5rem;
  height: 1.5rem;
}

.icon--filter-black {
  background-image: url("/img/icons/filter-black.svg");
  width: 1.625rem;
  height: 1.625rem;
}

.icon--circle-red {
  background-image: url("/img/icons/circle-red.svg");
  width: 1.1875rem;
  height: 1.1875rem;
}

.icon-phone-red {
  background-image: url("/img/icons/phone-red.svg");
  width: 2.625rem;
  height: 2.625rem;
}

.icon-user-red {
  background-image: url("/img/icons/user-red.svg");
  width: 2.625rem;
  height: 2.625rem;
}

.icon-download-red {
  background-image: url("/img/icons/download-red.svg");
  width: 2.625rem;
  height: 2.625rem;
}

.icon-cloud-red {
  background-image: url("/img/icons/cloud-red.svg");
  width: 2.625rem;
  height: 2.625rem;
}

.icon-anydesk {
  background-image: url("/img/icons/anydesk.svg");
  width: 3.125rem;
  height: 2.4375rem;
}

.icon-autograph {
  background-image: url("/img/icons/autograph.svg");
  width: 2.8125rem;
  height: 2.813rem;
}

.icon-receive-red {
  background-image: url("/img/icons/receive-red.svg");
  width: 1.5rem;
  height: 1.5rem;
  background-size: cover;
}

.icon-book-red {
  background-image: url("/img/icons/book-red.svg");
  width: 1.5rem;
  height: 1.5rem;
  background-size: cover;
}

@media only screen and (max-width: 980px){
  .icon--arrow-right-white {
    background-image: url("/img/icons/arrow-right-white.svg");
    width: 1rem;
    height: 1rem;
  }
  .icon--arrow-right--red {
    background-image: url("/img/icons/arrow-red-right.svg");
    width: 1rem;
    height: 1rem;
  }
  .icon-arrow-white-red-circle-left {
    background-image: url("/img/icons/arrow-white-red-circle-left.svg");
    object-fit: contain;
    width: 1.625rem;
    height: 1.625rem;
    border-radius: 50%;
  }
  .icon-arrow-white-red-circle-right {
    background-image: url("/img/icons/arrow-white-red-circle-right.svg");
    object-fit: contain;
    width: 1.625rem;
    height: 1.625rem;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 480px){
  .icon-anydesk {
    width: 2rem;
    height: 1.5625rem;
    background-size: contain;
  }

  .icon-autograph {
    width: 2rem;
    height: 2.00038rem;
    background-size: contain;
  }
  .icon-phone-red {
    width: 1.625rem;
    height: 1.625rem;
    background-size: contain;
  }

  .icon-user-red {
    width: 1.625rem;
    height: 1.625rem;
    background-size: contain;
  }

  .icon-download-red {
    width: 1.625rem;
    height: 1.625rem;
    background-size: contain;
  }

  .icon-cloud-red {
    width: 1.625rem;
    height: 1.625rem;
    background-size: contain;
  }
}