.template-detail {
  //padding-top: 3.25rem;
  padding-bottom: 3.25rem;
  * {
    color: #33363C;
    font-family: Montserrat, serif;
    font-size: 1.125rem;
    line-height: 160%; /* 1.8rem */
    padding-bottom: 1rem;
  }
  img {
    aspect-ratio: 2 / 1;
  }
}
.template-detail-text {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem;
  * {
    color: #33363C;
    font-family: Montserrat, serif;
    font-size: 1.125rem;
    line-height: 160%; /* 1.8rem */
    padding-bottom: 1rem;
  }
}
@media only screen and (max-width: 980px) {
  .template-detail-text {
    padding-top: 1.38rem;
    padding-bottom: 1.38rem;
    * {
      font-size: 0.875rem;
    }
  }
  .template-detail {
    padding-top: 1.38rem;
    padding-bottom: 1.38rem;
    * {
      font-size: 0.875rem;
    }
  }
}